import { Component, OnInit } from '@angular/core';
import { AddOnService } from 'src/app/_services/license/addon.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiRouteService } from 'src/app/_services/license/apiroute.service';
import { Subscription } from 'rxjs';
import { IApiRoute } from 'src/app/_models/apiroute';
import { FrontendRouteService } from 'src/app/_services/license/frontendroutes.service';
import { FormGroup, FormControl } from '@angular/forms';
import { IAddOnRequest } from 'src/app/_models/addon';
import IFrontendRoute from 'src/app/_models/frontendroute';

/**
 * Compontent with stepper in order to create new AddOns
 */
@Component({
	selector: 'app-create-addon',
	templateUrl: './create-addon.component.html',
	styleUrls: ['./create-addon.component.scss']
})
export class CreateAddonComponent implements OnInit {
	public apiRoutes: IApiRoute[] = [];
	public selectedApiRoutes: IApiRoute[] = [];

	public frontendRoutes: IFrontendRoute[] = [];
	public selectedFrontendRoutes: IFrontendRoute[] = [];

	public nameCtrl: FormControl = new FormControl();
	public createAddOnForm: FormGroup = new FormGroup({
		nameCtrl: this.nameCtrl
	});

	private apiRouteSubscription: Subscription;
	private frontendRouteSubscription: Subscription;

	constructor(
		private readonly addOnService: AddOnService,
		private readonly apiRouteService: ApiRouteService,
		private readonly frontendRouteService: FrontendRouteService,
		public dialogRef: MatDialogRef<CreateAddonComponent>
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.apiRouteService.getApiRoutes();
		await this.frontendRouteService.getFrontendRoutes();
	}

	public ngOnDestroy(): void {
		this.apiRouteSubscription.unsubscribe();
		this.frontendRouteSubscription.unsubscribe();
	}

	public compareFunction(
		o1: IApiRoute | IFrontendRoute,
		o2: IApiRoute | IFrontendRoute
	): boolean {
		return o1.id === o2.id;
	}

	public async submit(): Promise<void> {
		const frontendRoutes: string[] = [];
		const apiRoutes: string[] = [];

		this.selectedApiRoutes.forEach((apiRoute: IApiRoute) => {
			apiRoutes.push(apiRoute.id);
		});

		this.selectedFrontendRoutes.forEach((frontendRoute: IFrontendRoute) => {
			frontendRoutes.push(frontendRoute.id);
		});

		const requestBody: IAddOnRequest = {
			name: this.nameCtrl.value,
			apiRoutes: apiRoutes,
			routes: frontendRoutes,
			remove: undefined
		};

		await this.addOnService.createAddOn(requestBody);
	}

	public async onConfirm(): Promise<void> {
		await this.submit();

		this.dialogRef.close(true);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async initSubscriptions(): Promise<void> {
		this.apiRouteSubscription = this.apiRouteService.apiRoutes.subscribe(
			(apiRoutes: IApiRoute[]) => {
				this.apiRoutes = apiRoutes;
			}
		);

		this.frontendRouteSubscription = this.frontendRouteService.frontendRoutes.subscribe(
			(frontendroutes: IFrontendRoute[]) => {
				this.frontendRoutes = frontendroutes;
			}
		);
	}
}
