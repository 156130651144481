import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IInviteUserRequest } from 'src/app/_models/users';
import { UsersMigrationService } from 'src/app/_services/users/user-migration.service';
import { Subscription } from 'rxjs';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { IGroupResponse } from 'src/app/_models/groups';
import { UsersService } from 'src/app/_services/users/users.service';
import { environment } from 'src/environments/environment';
import { ILicense } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

/**
 * Component for inviting external users to the application
 *
 * @export
 * @class InviteUserModalComponent
 */
@Component({
	selector: 'app-invite-user-modal',
	templateUrl: './invite-user-modal.component.html',
	styleUrls: ['./invite-user-modal.component.scss']
})
export class InviteUserModalComponent implements OnInit {
	public groupCtrl: FormControl = new FormControl();
	public licenseCtrl: FormControl = new FormControl();
	public emailCtrl: FormControl = new FormControl(undefined, [
		Validators.email
	]);
	public inviteForm: FormGroup = new FormGroup({
		groupCtrl: this.groupCtrl,
		licenseCtrl: this.licenseCtrl,
		emailCtrl: this.emailCtrl
	});

	public licenses: ILicense[] = [];
	public groups: IGroupResponse[] = [];
	private licenseSubscription: Subscription;
	private groupSubscription: Subscription;

	constructor(
		public userService: UsersService,
		public groupService: GroupsService,
		public licenseService: LicenseService,
		public dialogRef: MatDialogRef<InviteUserModalComponent>
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.groupService.getGroups();
		await this.licenseService.getLicense();
	}

	public ngOnDestroy(): void {
		this.groupSubscription.unsubscribe();
		this.licenseSubscription.unsubscribe();
	}

	public groupDisplayFn(group: IGroupResponse): string {
		return group ? group.graphGroup.displayName : '';
	}

	public licenseDisplayFn(license: ILicense): string {
		return license ? license.name + ' - ' + license.id : '';
	}

	public async onConfirm(): Promise<void> {
		if (this.inviteForm.valid) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(): Promise<void> {
		const licenseVal: string | undefined =
			this.licenseCtrl.value !== null ? this.licenseCtrl.value.id : undefined;

		const body: IInviteUserRequest = {
			invitation: {
				invitedUserEmailAddress: this.emailCtrl.value,
				invitedUserType: 'guest',
				sendInvitationMessage: false,
				inviteRedirectUrl: environment.PortalURL
			},
			groupId: this.groupCtrl.value.graphGroup.id,
			license: licenseVal
		};

		await this.userService.inviteUser(body);
	}

	private async initSubscriptions(): Promise<void> {
		this.groupSubscription = this.groupService.groups.subscribe(
			(groups: IGroupResponse[]) => {
				this.groups = groups;
			}
		);

		this.licenseSubscription = this.licenseService.license.subscribe(
			(licenses: ILicense[]) => {
				this.licenses = licenses;
			}
		);
	}
}
