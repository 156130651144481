import { Component, OnInit, Inject } from '@angular/core';
import { IAddOn } from 'src/app/_models/addon';
import { Subscription } from 'rxjs';
import { AddOnService } from 'src/app/_services/license/addon.service';
import { LicenseService } from 'src/app/_services/license/license.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILicense, ILicenseRequest } from 'src/app/_models/license';

/**
 * Used to select an addOn for a license
 */
@Component({
	selector: 'app-add-on-selector',
	templateUrl: './add-on-selector.component.html',
	styleUrls: ['./add-on-selector.component.scss']
})
export class AddOnSelectorComponent implements OnInit {
	public addOns: IAddOn[] = [];
	public selectedAddOns: IAddOn[] = [];
	private addOnSubscription: Subscription;

	constructor(
		private readonly licenseService: LicenseService,
		private readonly addOnService: AddOnService,
		public dialogRef: MatDialogRef<AddOnSelectorComponent>,
		@Inject(MAT_DIALOG_DATA) public license: ILicense
	) {
		this.initSubscriptions();
	}

	public compareFunction(o1: ILicense, o2: ILicense): boolean {
		return o1.id === o2.id;
	}

	public async ngOnInit(): Promise<void> {
		this.addOnService.getAddOns();
	}

	public ngOnDestroy(): void {
		this.addOnSubscription.unsubscribe();
	}

	public checkIfAddOnIsSelected(id: string): boolean {
		return this.license.addOns.some((addOn: IAddOn) => {
			return addOn.id === id;
		});
	}

	public async onConfirm(): Promise<void> {
		const toAdd: string[] = [];
		const toRemove: string[] = [];

		this.license.addOns.forEach((addOn: IAddOn) => {
			const isSelected: boolean = this.selectedAddOns.some(
				(selectedAddon: IAddOn) => {
					return addOn.id === selectedAddon.id;
				}
			);
			if (!isSelected) {
				toRemove.push(addOn.id);
			}
		});

		this.selectedAddOns.forEach((addOn: IAddOn) => {
			const isAlreadySelected: boolean = this.checkIfAddOnIsSelected(addOn.id);
			if (!isAlreadySelected) {
				toAdd.push(addOn.id);
			}
		});

		await this.submit(toRemove, toAdd);
		this.dialogRef.close(true);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(toRemove: string[], toAdd: string[]): Promise<void> {
		if (toRemove.length > 0) {
			const deleteRequest: ILicenseRequest = {
				name: this.license.name,
				addOns: toRemove,
				removeAddOn: true,
				active: undefined
			};
			await this.licenseService.patchLicense(deleteRequest, this.license.id);
		}

		if (toAdd.length > 0) {
			const addRequest: ILicenseRequest = {
				name: this.license.name,
				addOns: toAdd,
				removeAddOn: false,
				active: undefined
			};
			await this.licenseService.patchLicense(addRequest, this.license.id);
		}
	}

	private async initSubscriptions(): Promise<void> {
		this.addOnSubscription = this.addOnService.addOns.subscribe(
			(addOns: IAddOn[]) => {
				this.addOns = addOns;
				if (this.addOns.length > 0) {
					this.addOns.forEach((addOn: IAddOn) => {
						if (this.checkIfAddOnIsSelected(addOn.id)) {
							this.selectedAddOns.push(addOn);
						}
					});
				}
			}
		);
	}
}
