import { Component, Inject } from '@angular/core';
import { FrontendRouteService } from 'src/app/_services/license/frontendroutes.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	FormControl,
	FormGroup,
	ValidatorFn,
	ValidationErrors
} from '@angular/forms';
import { ApiRouteService } from 'src/app/_services/license/apiroute.service';

/**
 * Modal to delete a frontendRoute
 */
@Component({
	selector: 'app-delete-api-route',
	templateUrl: './delete-api-route.component.html',
	styleUrls: ['./delete-api-route.component.scss']
})
export class DeleteApiRouteComponent {
	public idCtrl: FormControl = new FormControl(undefined, [
		this.validateIdInput()
	]);
	public deleteForm: FormGroup = new FormGroup({
		idCtrl: this.idCtrl
	});

	constructor(
		private readonly apiRouteService: ApiRouteService,
		@Inject(MAT_DIALOG_DATA) public data: string,
		public dialogRef: MatDialogRef<DeleteApiRouteComponent>
	) {}

	public async onConfirm(): Promise<void> {
		if (this.deleteForm.valid) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(): Promise<void> {
		await this.apiRouteService.deleteApiRoute(this.data);
	}

	private validateIdInput(): ValidatorFn {
		return (control: FormControl): ValidationErrors => {
			if (control.value === this.data) {
				return null;
			} else {
				return { error: 'Falsche Id' };
			}
		};
	}
}
