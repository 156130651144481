import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { AuthGuardService } from './_guards/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupMigrationComponent } from './group-migration/group-migration.component';
import { UserMigrationComponent } from './user-migration/user-migration.component';
import { LicenseComponent } from './license/license.component';
import { AddonComponent } from './addon/addon.component';
import { FrontendRoutesComponent } from './frontendroutes/frontendroutes.component';
import { ApiRoutesComponent } from './apiroutes/apiroutes.component';

const routes: Routes = [
	{ path: '', component: UsersComponent, canActivate: [AuthGuardService] },
	{ path: 'login', component: LoginComponent },
	{ path: 'users', component: UsersComponent, canActivate: [AuthGuardService] },
	{
		path: 'groups',
		component: GroupsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'group-migration',
		component: GroupMigrationComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'user-migration',
		component: UserMigrationComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'license',
		component: LicenseComponent,
		canActivate: [AuthGuardService]
	},
	{ path: 'addon', component: AddonComponent, canActivate: [AuthGuardService] },
	{
		path: 'frontendroutes',
		component: FrontendRoutesComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'apiroutes',
		component: ApiRoutesComponent,
		canActivate: [AuthGuardService]
	},
	{ path: '**', redirectTo: '' }
];

/**
 * Routing module
 * @export
 * @class AppRoutingModule
 */
@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
