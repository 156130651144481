<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="usersDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="graphUser.id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
			<td mat-cell *matCellDef="let user">{{ user.graphUser.id }}</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="graphUser.displayName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let user">{{ user.graphUser.displayName }}</td>
		</ng-container>

		<!-- Mail Column -->
		<ng-container matColumnDef="graphUser.mail">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Mail</th>
			<td mat-cell *matCellDef="let user">{{ user.graphUser.mail }}</td>
		</ng-container>

		<!-- UserPrincipal Column -->
		<ng-container matColumnDef="graphUser.userPrincipalName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Principal</th>
			<td mat-cell *matCellDef="let user">
				{{ user.graphUser.userPrincipalName }}
			</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef>Optionen</th>
			<td mat-cell *matCellDef="let user">
				<button
					mat-raised-button
					color="primary"
					(click)="
						openCreateMapping(user.graphUser.id, user.graphUser.displayName)
					"
				>
					<mat-icon>add</mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
