import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/_services/users/users.service';
import { IUserRequest } from 'src/app/_models/users';
import { IGroupResponse } from 'src/app/_models/groups';
import { Subscription } from 'rxjs';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { ILicense } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

/**
 * Create User Modal
 *
 * @export
 * @class CreateUserModalComponent
 */
@Component({
	selector: 'app-create-user-modal',
	templateUrl: './create-user-modal.component.html',
	styleUrls: ['./create-user-modal.component.scss']
})
export class CreateUserModalComponent implements OnInit {
	public nameCtrl: FormControl = new FormControl();
	public mailCtrl: FormControl = new FormControl();
	public groupCtrl: FormControl = new FormControl();
	public licenseCtrl: FormControl = new FormControl();
	public passwordCtrl: FormControl = new FormControl();
	public passwordChangeCtrl: FormControl = new FormControl();
	public createForm: FormGroup = new FormGroup({
		nameCtrl: this.nameCtrl,
		mailCtrl: this.mailCtrl,
		groupCtrl: this.groupCtrl,
		licenseCtrl: this.licenseCtrl,
		passwordCtrl: this.passwordCtrl,
		passwordChangeCtrl: this.passwordChangeCtrl
	});

	public licenses: ILicense[] = [];
	public groups: IGroupResponse[] = [];
	private groupSubscription: Subscription;
	private licenseSubscription: Subscription;

	constructor(
		public dialogRef: MatDialogRef<CreateUserModalComponent>,
		private readonly usersService: UsersService,
		public groupService: GroupsService,
		public licenseService: LicenseService
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.groupService.getGroups();
		await this.licenseService.getLicense();
	}

	public ngOnDestroy(): void {
		this.groupSubscription.unsubscribe();
		this.licenseSubscription.unsubscribe();
	}

	public groupDisplayFn(group: IGroupResponse): string {
		return group ? group.graphGroup.displayName : '';
	}

	public licenseDisplayFn(license: ILicense): string {
		return license ? license.name + ' - ' + license.id : '';
	}

	public async onConfirm(): Promise<void> {
		if (this.createForm.valid) {
			await this.submitForm();
			await this.usersService.getUsers();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submitForm(): Promise<void> {
		//TODO validate form of passwort
		const newUser: IUserRequest = {
			graphUser: {
				displayName: this.nameCtrl.value,
				mailNickname: this.mailCtrl.value,
				accountEnabled: true,
				userPrincipalName:
					this.mailCtrl.value + '@intelligencesuite.onmicrosoft.com',
				passwordProfile: {
					forceChangePasswordNextSignIn: this.passwordChangeCtrl.value,
					password: this.passwordCtrl.value
				}
			},
			license:
				this.licenseCtrl.value === null ? undefined : this.licenseCtrl.value.id,
			groupId: this.groupCtrl.value.graphGroup.id
		};

		await this.usersService.createUser(newUser);
	}

	private async initSubscriptions(): Promise<void> {
		this.groupSubscription = this.groupService.groups.subscribe(
			(groups: IGroupResponse[]) => {
				this.groups = groups;
			}
		);

		this.licenseSubscription = this.licenseService.license.subscribe(
			(licenses: ILicense[]) => {
				this.licenses = licenses;
			}
		);
	}
}
