import { Component, Inject } from '@angular/core';
import { FrontendRouteService } from 'src/app/_services/license/frontendroutes.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import IFrontendRoute, {
	IFrontendRouteRequest,
	IConfig
} from 'src/app/_models/frontendroute';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * EditFrontendRouteComponent is responsible for updating a
 * FrontendRoute
 */
@Component({
	selector: 'app-edit-frontend-route',
	templateUrl: './edit-frontend-route.component.html',
	styleUrls: ['./edit-frontend-route.component.scss']
})
export class EditFrontendRouteComponent {
	public configs: IConfig[] = [];

	public descriptionCtrl: FormControl = new FormControl();
	public keyCtrl: FormControl = new FormControl();
	public editForm: FormGroup = new FormGroup({
		keyCtrl: this.keyCtrl,
		descriptionCtrl: this.descriptionCtrl
	});

	public pathCtrl: FormControl = new FormControl();
	public labelCtrl: FormControl = new FormControl();
	public editConfigForm: FormGroup = new FormGroup({
		pathCtrl: this.pathCtrl,
		labelCtrl: this.labelCtrl
	});

	constructor(
		private readonly frontendRouteService: FrontendRouteService,
		public dialogRef: MatDialogRef<EditFrontendRouteComponent>,
		@Inject(MAT_DIALOG_DATA) public frontendRoute: IFrontendRoute
	) {
		this.keyCtrl.setValue(frontendRoute.key);
		this.descriptionCtrl.setValue(frontendRoute.description);
		this.configs = [...frontendRoute.config];
	}

	public async onConfirm(): Promise<void> {
		if (!this.editForm.valid) {
			this.dialogRef.close(false);
		}

		if (
			this.keyCtrl.value !== this.frontendRoute.key ||
			!this.configsAreEqual(this.configs, this.frontendRoute.config) ||
			this.descriptionCtrl.value !== this.frontendRoute.description
		) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	public addConfig(): void {
		const config: IConfig = {
			path: this.pathCtrl.value,
			label: this.labelCtrl.value
		};

		this.configs.push(config);
		this.editConfigForm.reset();
	}

	public removeConfig(config: IConfig): void {
		this.configs.splice(this.configs.indexOf(config), 1);
	}

	public async submit(): Promise<void> {
		const patchBody: IFrontendRouteRequest = {
			key: this.keyCtrl.value,
			config: this.configs,
			description: this.descriptionCtrl.value
		};

		await this.frontendRouteService.patchFrontendRoute(
			patchBody,
			this.frontendRoute.id
		);
	}

	private configsAreEqual(config1: IConfig[], config2: IConfig[]): boolean {
		if (config1.length !== config2.length) {
			return false;
		}

		const arr1: IConfig[] = config1.sort();
		const arr2: IConfig[] = config2.sort();

		arr1.forEach((config: IConfig, i: number) => {
			if (arr2[i] !== config) {
				return false;
			}
		});

		return true;
	}
}
