<div>
	<h1 mat-dialog-title>
		Neues AddOn erstellen
	</h1>

	<mat-dialog-content>
		<form name="createAddOnForm">
			<mat-horizontal-stepper class="stepper" labelPosition="bottom" #stepper>
				<mat-step>
					<ng-template matStepLabel>Namen für das AddOn angeben</ng-template>
					<div>
						<mat-form-field appearance="outline">
							<mat-label>AddOn</mat-label>
							<input
								[formControl]="nameCtrl"
								matInput
								placeholder="Name"
								required
							/>
							<mat-hint>Name für das AddOn</mat-hint>
						</mat-form-field>
					</div>
					<button
						[disabled]="this.nameCtrl.invalid"
						mat-raised-button
						color="primary"
						matStepperNext
					>
						Weiter
					</button>
				</mat-step>
				<mat-step optional>
					<ng-template matStepLabel>API-Routen auswählen</ng-template>
					<mat-dialog-content class="selection-dialog">
						<mat-selection-list
							*ngIf="this.apiRoutes?.length > 0"
							[(ngModel)]="selectedApiRoutes"
							name="apiRoutes"
							[compareWith]="compareFunction"
						>
							<mat-list-option
								class="selection-option stock-hover-option"
								*ngFor="let route of apiRoutes"
								[value]="route"
							>
								{{ route.route }} ({{ route.method }})
							</mat-list-option>
						</mat-selection-list>
					</mat-dialog-content>
					<div>
						<button mat-raised-button color="secondary" matStepperPrevious>
							Zurück
						</button>
						<button mat-raised-button color="primary" matStepperNext>
							Weiter
						</button>
					</div>
				</mat-step>
				<mat-step optional>
					<ng-template matStepLabel>Frontend-Routen auswählen</ng-template>
					<mat-dialog-content class="selection-dialog">
						<mat-selection-list
							*ngIf="this.frontendRoutes?.length > 0"
							[(ngModel)]="selectedFrontendRoutes"
							[compareWith]="compareFunction"
							name="frontendRoutes"
						>
							<mat-list-option
								class="selection-option stock-hover-option"
								*ngFor="let route of frontendRoutes"
								[value]="route"
							>
								Key: {{ route.key }}, Beschreibung: {{ route.description }}
							</mat-list-option>
						</mat-selection-list>
					</mat-dialog-content>
					<div>
						<button mat-raised-button color="secondary" matStepperPrevious>
							Zurück
						</button>
						<button mat-raised-button color="primary" matStepperNext>
							Weiter
						</button>
					</div>
				</mat-step>
				<mat-step>
					<ng-template matStepLabel>Zusammenfassung</ng-template>

					<h3>Name:</h3>
					<p>{{ this.nameCtrl.value }}</p>

					<div *ngIf="this.selectedApiRoutes?.length > 0">
						<h3>Ausgewählte API-Routen:</h3>
						<mat-dialog-content>
							<mat-list>
								<mat-list-item
									class="selection-option stock-hover-item"
									*ngFor="let route of selectedApiRoutes"
								>
									<p>{{ route.route }} ({{ route.method }})</p>
								</mat-list-item>
							</mat-list>
						</mat-dialog-content>
					</div>

					<div *ngIf="this.selectedFrontendRoutes?.length > 0">
						<h3>Ausgewählte Frontend-Routen:</h3>
						<mat-dialog-content>
							<mat-list>
								<mat-list-item
									class="selection-option stock-hover-item"
									*ngFor="let route of selectedFrontendRoutes"
								>
									<p>
										Key: {{ route.key }}, Beschreibung: {{ route.description }}
									</p>
								</mat-list-item>
							</mat-list>
						</mat-dialog-content>
					</div>

					<div>
						<button mat-raised-button color="primary" (click)="onConfirm()">
							Speichern
						</button>
						<button mat-raised-button color="secondary" matStepperPrevious>
							Zurück
						</button>
						<button mat-raised-button color="secondary" (click)="onClose()">
							Schließen
						</button>
					</div>
				</mat-step>
			</mat-horizontal-stepper>
		</form>
	</mat-dialog-content>
</div>
