import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { UsersMigrationService } from '../_services/users/user-migration.service';
import { IUserResponse } from '../_models/users';
import { CreateUserMappingModalComponent } from '../_components/user/create-user-mapping-modal/create-user-mapping-modal.component';

/**
 * User migration component
 *
 * @export
 * @class UserMigrationComponent
 */
@Component({
	selector: 'app-user-migration',
	templateUrl: './user-migration.component.html',
	styleUrls: ['./user-migration.component.scss']
})
export class UserMigrationComponent implements OnInit {
	public initialData: IUserResponse[] = [];
	public usersDataSource: MatTableDataSource<
		IUserResponse
	> = new MatTableDataSource(this.initialData);
	public displayedColumns: string[] = [
		'graphUser.id',
		'graphUser.mail',
		'graphUser.userPrincipalName',
		'graphUser.displayName',
		'edit'
	];
	public isLoading: boolean = true;

	private usersSub: Subscription;

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly usersMigrationService: UsersMigrationService,
		public readonly dialog: MatDialog,
		private readonly breakpointObserver: BreakpointObserver
	) {
		breakpointObserver
			.observe(['(max-width: 1300px)'])
			.subscribe((result: BreakpointState) => {
				if (result.matches) {
					this.displayedColumns = [
						'graphUser.mail',
						'graphUser.displayName',
						'edit'
					];
				} else {
					this.displayedColumns = [
						'graphUser.id',
						'graphUser.mail',
						'graphUser.userPrincipalName',
						'graphUser.displayName',
						'edit'
					];
				}
			});
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.usersMigrationService.getUsers();
	}

	public ngOnDestory(): void {
		this.usersSub.unsubscribe();
	}

	public async openCreateMapping(id: string, name: string): Promise<void> {
		const dialogRef: MatDialogRef<CreateUserMappingModalComponent> = this.dialog.open(
			CreateUserMappingModalComponent,
			{
				panelClass: 'custom-modalbox',
				data: { id: id, name: name }
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.usersMigrationService.getUsers();
			}, 1000);
		}
	}

	private initSubscriptions(): void {
		this.usersSub = this.usersMigrationService.users.subscribe(
			(users: IUserResponse[]) => {
				if (users.length > 0) {
					this.isLoading = false;
				}
				this.usersDataSource = new MatTableDataSource<IUserResponse>(users);
				this.usersDataSource.sortingDataAccessor = (
					sortGroups: IUserResponse,
					property: string
				): string | number => {
					const propPath: string[] = property.split('.');
					const value: string = propPath.reduce(
						(curObj: IUserResponse, prop: string) => {
							return curObj[prop];
						},
						sortGroups
					);

					return value;
				};
				this.usersDataSource.sort = this.sort;
				this.usersDataSource.paginator = this.paginator;
			}
		);
	}
}
