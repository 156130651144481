<div class="sendInviationModal">
	<h1 mat-dialog-title>
		Einladung senden
	</h1>

	<p>Soll eine Einladung per Mail an den Nutzer gesendet werden?</p>

	<div class="confirmationBtn" mat-dialog-actions>
		<button mat-raised-button color="accent" (click)="onConfirm()">
			Bestätigen
		</button>
		<button mat-raised-button color="secondary" (click)="onClose()">
			Schließen
		</button>
	</div>
</div>
