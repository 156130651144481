import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';
import {
	IUserResponse,
	IUserRequest,
	IInviteUserRequest
} from '../../_models/users';

/**
 * Users Service for fetching and updating data
 *
 * @export
 * @class UsersService
 */
@Injectable({
	providedIn: 'root'
})
export class UsersService {
	public readonly users: Observable<IUserResponse[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _usersSubject: BehaviorSubject<
		IUserResponse[]
	> = new BehaviorSubject<IUserResponse[]>({} as IUserResponse[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.users = this._usersSubject.asObservable();
	}

	public async getUsers(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/users';

		this.httpService
			.getData<IUserResponse[]>(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(users: IUserResponse[]) => {
					this._usersSubject.next(users);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createUser(body: IUserRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/users';
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Nutzer wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen der Nutzer fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async patchUser(body: IUserRequest, id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/users/${id}`;
		this.httpService
			.patchData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Nutzer wurde erfolgreich geupdated');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Updaten der Nutzer fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async deleteUser(id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/users/${id}`;
		this.httpService
			.deleteData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Nutzer wurde erfolgreich gelöscht');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Löschen der Nutzer fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async inviteUser(body: IInviteUserRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/users/invite';
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success(
						'Nutzer wurde zur Anwendung hinzugefügt. Einladung steht noch aus.'
					);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Nutzer konnte nicht hinzugefügt werden: ' + error.error.error
					);
				}
			);
	}

	public async sendInvitationEmail(id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/users/invitation/${id}`;
		this.httpService
			.postData(
				requestURL,
				{},
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Einladung wurde verschickt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Nutzer konnte nicht eingeladen werden: ' + error.error.error
					);
				}
			);
	}
}
