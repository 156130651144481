import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFrontendRouteRequest, IConfig } from 'src/app/_models/frontendroute';
import { FormGroup, FormControl, Form } from '@angular/forms';
import { FrontendRouteService } from 'src/app/_services/license/frontendroutes.service';

/**
 * Modal for creating a new FrontendRoute
 */
@Component({
	selector: 'app-create-frontend-route',
	templateUrl: './create-frontend-route.component.html',
	styleUrls: ['./create-frontend-route.component.scss']
})
export class CreateFrontendRouteComponent {
	public configs: IConfig[] = [];
	public keyCtrl: FormControl = new FormControl();
	public descriptionCtrl: FormControl = new FormControl();
	public createFrontendRouteForm: FormGroup = new FormGroup({
		keyCtrl: this.keyCtrl,
		descriptionCtrl: this.descriptionCtrl
	});

	public pathCtrl: FormControl = new FormControl();
	public labelCtrl: FormControl = new FormControl();
	public addConfigForm: FormGroup = new FormGroup({
		pathCtrl: this.pathCtrl,
		labelCtrl: this.labelCtrl
	});

	constructor(
		public frontendRouteService: FrontendRouteService,
		public dialogRef: MatDialogRef<CreateFrontendRouteComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IFrontendRouteRequest
	) {}

	public async onConfirm(): Promise<void> {
		if (this.createFrontendRouteForm.valid) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public async submit(): Promise<void> {
		const body: IFrontendRouteRequest = {
			key: this.keyCtrl.value,
			config: this.configs,
			description: this.descriptionCtrl.value
		};

		await this.frontendRouteService.createFrontendRoute(body);
	}

	public addConfig(): void {
		const config: IConfig = {
			path: this.pathCtrl.value,
			label: this.labelCtrl.value
		};

		this.configs.push(config);
		this.addConfigForm.reset();
	}

	public removeConfig(config: IConfig): void {
		this.configs.splice(this.configs.indexOf(config), 1);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}
}
