import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ICompany, ICompaniesResponse } from '../_models/company';
import { HttpService } from './http.service';
import { AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';

/**
 * CompanyService for fetching and updating data
 *
 * @export
 * @class CompanyService
 */
@Injectable({
	providedIn: 'root'
})
export class CompanyService {
	public readonly companies: Observable<ICompany[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _companiesSubject: BehaviorSubject<
		ICompany[]
	> = new BehaviorSubject<ICompany[]>({} as ICompany[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.companies = this._companiesSubject.asObservable();
	}

	public async getCompanies(): Promise<void> {
		const requestURL: string = this.proxyUrl + 'api/companies';

		this.httpService
			.getData<ICompaniesResponse>(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(companies: ICompaniesResponse) => {
					this._companiesSubject.next(companies.rows);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}
}
