<div class="editModal">
	<h1 mat-dialog-title>
		License bearbeiten
	</h1>

	<form name="editForm">
		<div class="edit-form" mat-dialog-content>
			<mat-form-field appearance="outline">
				<mat-label>License</mat-label>
				<input matInput placeholder="Name" [formControl]="nameCtrl" required />
				<mat-hint>Name für die Lizenz</mat-hint>
			</mat-form-field>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button mat-raised-button color="primary" (click)="onConfirm()">
				Speichern
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
