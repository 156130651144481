<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="usersDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="graphUser.id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
			<td mat-cell *matCellDef="let user">{{ user.graphUser.id }}</td>
		</ng-container>

		<!-- Group Id Column -->
		<ng-container matColumnDef="userMapping.group.groupName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Gruppenname</th>
			<td mat-cell *matCellDef="let user">
				{{ user.userMapping.group.groupName }}
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="graphUser.displayName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let user">{{ user.graphUser.displayName }}</td>
		</ng-container>

		<!-- Mail Column -->
		<ng-container matColumnDef="graphUser.mail">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Mail</th>
			<td mat-cell *matCellDef="let user">{{ user.graphUser.mail }}</td>
		</ng-container>

		<!-- UserPrincipal Column -->
		<ng-container matColumnDef="graphUser.userPrincipalName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Principal</th>
			<td mat-cell *matCellDef="let user">
				{{ user.graphUser.userPrincipalName }}
			</td>
		</ng-container>

		<!-- License -->
		<ng-container matColumnDef="userMapping.license">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>License</th>
			<td mat-cell *matCellDef="let user">
				{{ user.userMapping.license?.name }}
			</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef>Optionen</th>
			<td mat-cell *matCellDef="let user">
				<div class="button-div">
					<button
						class="edit-btn"
						mat-raised-button
						color="primary"
						(click)="openEditModal(user)"
					>
						<mat-icon>edit</mat-icon>
					</button>
					<button
						class="delete-btn"
						mat-raised-button
						color="warn"
						(click)="openDeleteModal(user.graphUser.id)"
					>
						<mat-icon>delete</mat-icon>
					</button>
					<button
						*ngIf="user.graphUser.externalUserState === 'PendingAcceptance'"
						class="invite-btn"
						mat-raised-button
						color="primary"
						(click)="sendInvitation(user.graphUser.id)"
					>
						<mat-icon>email</mat-icon>
					</button>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

	<div class="add-btn-group">
		<button
			class="create-btn"
			mat-fab
			color="primary"
			(click)="openCreateModal()"
		>
			<mat-icon>add</mat-icon>
		</button>
		<button mat-fab color="primary" (click)="openInviteModal()">
			<mat-icon>email</mat-icon>
		</button>
	</div>
</div>
