<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="frontendRouteDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Parse Id</th>
			<td mat-cell *matCellDef="let frontendRoute">{{ frontendRoute.id }}</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="key">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Key</th>
			<td mat-cell *matCellDef="let frontendRoute">{{ frontendRoute.key }}</td>
		</ng-container>

		<!-- Beschreibung Column -->
		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Beschreibung</th>
			<td mat-cell *matCellDef="let frontendRoute">
				{{ frontendRoute.description }}
			</td>
		</ng-container>

		<!-- Configs Column -->
		<ng-container matColumnDef="config">
			<th mat-header-cell *matHeaderCellDef>API-Routen</th>
			<td mat-cell *matCellDef="let frontendRoute">
				<mat-chip-list>
					<mat-chip
						class="chip-style"
						color="primary"
						(click)="openEditModal(frontendRoute)"
						selected
					>
						{{ frontendRoute.config.length }}</mat-chip
					>
				</mat-chip-list>
			</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit" class="edit-column">
			<th mat-header-cell class="column-right-align" *matHeaderCellDef>
				Optionen
			</th>
			<td mat-cell class="column-right-align" *matCellDef="let frontendRoute">
				<div class="button-div">
					<button
						class="edit-btn"
						mat-raised-button
						color="primary"
						(click)="openEditModal(frontendRoute)"
					>
						<mat-icon>edit</mat-icon>
					</button>
					<button
						class="delete-btn"
						mat-raised-button
						color="warn"
						(click)="openDeleteModal(frontendRoute.id)"
					>
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let apiroute; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

	<button class="add-btn" mat-fab color="primary" (click)="openCreateModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>
