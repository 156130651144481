import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';
import { IUserResponse, IUserRequest } from '../../_models/users';

/**
 * Users Service for fetching and updating data
 *
 * @export
 * @class UsersService
 */
@Injectable({
	providedIn: 'root'
})
export class UsersMigrationService {
	public readonly users: Observable<IUserResponse[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _usersSubject: BehaviorSubject<
		IUserResponse[]
	> = new BehaviorSubject<IUserResponse[]>({} as IUserResponse[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.users = this._usersSubject.asObservable();
	}

	public async getUsers(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/maintenance/users';

		this.httpService
			.getData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(users: IUserResponse[]) => {
					this._usersSubject.next(users);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createMapping(id: string, body: IUserRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/maintenance/users/${id}`;
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Mapping wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen des Mappings fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}
}
