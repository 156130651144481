/*eslint-disable @typescript-eslint/tslint/config */
export const environment = {
	environment: 'qa',
	production: false,
	aConsentScopes: ['https://graph.microsoft.com/Directory.ReadWrite.All'],
	msalConfig: {
		auth: {
			clientId: 'f8418c79-e2d7-44e2-b723-a804d5823651',
			authority:
				'https://login.microsoftonline.com/' +
				'a2f24372-0d4c-4de8-b323-fce0e293e56d/',
			validateAuthority: true,
			navigateToLoginRequestUrl: true
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: true
		}
	},
	ProxyApi: 'https://proxy.output-analytics.de/',
	PortalURL: 'https://portal.output-analytics.de'
};
