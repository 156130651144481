<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="groupsDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="graphGroup.id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Group Id</th>
			<td mat-cell *matCellDef="let group">{{ group.graphGroup.id }}</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="graphGroup.displayName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let group">
				{{ group.graphGroup.displayName }}
			</td>
		</ng-container>

		<!-- Mandaten Id -->
		<ng-container matColumnDef="groupMapping.mandantenId">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Mandaten Id</th>
			<td mat-cell *matCellDef="let group">
				<div *ngIf="group.groupMapping.mandantenId !== undefined">
					{{ group.groupMapping.mandantenId }}
				</div>
			</td>
		</ng-container>

		<!-- License -->
		<ng-container matColumnDef="groupMapping.license">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>License</th>
			<td mat-cell *matCellDef="let group">
				{{ group.groupMapping.license.name }}
			</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef>Optionen</th>
			<td mat-cell *matCellDef="let group">
				<div class="button-div">
					<button
						class="edit-btn"
						mat-raised-button
						color="primary"
						(click)="openEditModal(group)"
					>
						<mat-icon>edit</mat-icon>
					</button>
					<button
						class="delete-btn"
						mat-raised-button
						color="warn"
						(click)="openDeleteModal(group.graphGroup.id)"
					>
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let group; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

	<button class="add-btn" mat-fab color="primary" (click)="openCreateModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>
