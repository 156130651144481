import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IAddOn } from '../_models/addon';
import { AddOnService } from '../_services/license/addon.service';
import { ApiRouteSelectorComponent } from '../_components/addon/api-route-selector/api-route-selector.component';
import { FrontendRouteSelectorComponent } from '../_components/addon/frontend-route-selector/frontend-route-selector.component';
import { DeleteAddonComponent } from '../_components/addon/delete-addon/delete-addon.component';
import { EditAddonComponent } from '../_components/addon/edit-addon/edit-addon.component';
import { CreateAddonComponent } from '../_components/addon/create-addon/create-addon.component';

/**
 * AddOnComponent is responsible for managing the AddOns for a license
 */
@Component({
	selector: 'app-addon',
	templateUrl: './addon.component.html',
	styleUrls: ['./addon.component.scss']
})
export class AddonComponent implements OnInit {
	public initalData: IAddOn[] = [];
	public addOnDataSource: MatTableDataSource<IAddOn> = new MatTableDataSource(
		this.initalData
	);
	public displayedColumns: string[] = [
		'addOn.id',
		'addOn.name',
		'addOn.apiRoutes',
		'addOn.frontendRoutes',
		'edit'
	];
	public isLoading: boolean = true;

	private addOnSubscription: Subscription;

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly addOnService: AddOnService,
		public readonly dialog: MatDialog
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.addOnService.getAddOns();
	}

	public ngOnDestroy(): void {
		this.addOnSubscription.unsubscribe();
	}

	public async openApiSelection(addOn: IAddOn): Promise<void> {
		const dialogRef: MatDialogRef<ApiRouteSelectorComponent> = this.dialog.open(
			ApiRouteSelectorComponent,
			{
				panelClass: 'selection-modalbox',
				data: addOn
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.addOnService.getAddOns();
		}
	}

	public async openFrontendRouteSelection(addOn: IAddOn): Promise<void> {
		const dialogRef: MatDialogRef<FrontendRouteSelectorComponent> = this.dialog.open(
			FrontendRouteSelectorComponent,
			{
				panelClass: 'selection-modalbox',
				data: addOn
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.addOnService.getAddOns();
		}
	}

	public async openCreateModal(): Promise<void> {
		const dialogRef: MatDialogRef<CreateAddonComponent> = this.dialog.open(
			CreateAddonComponent,
			{
				panelClass: 'custom-modalbox'
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.addOnService.getAddOns();
			}, 1000);
		}
	}

	public async openEditModal(addOn: IAddOn): Promise<void> {
		const dialogRef: MatDialogRef<EditAddonComponent> = this.dialog.open(
			EditAddonComponent,
			{
				panelClass: 'custom-modalbox',
				data: addOn
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.addOnService.getAddOns();
		}
	}

	public async openDeleteModal(id: string): Promise<void> {
		const dialogRef: MatDialogRef<DeleteAddonComponent> = this.dialog.open(
			DeleteAddonComponent,
			{
				panelClass: 'custom-modalbox',
				data: id
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.addOnService.getAddOns();
		}
	}

	private async initSubscriptions(): Promise<void> {
		this.addOnSubscription = this.addOnService.addOns.subscribe(
			(addOn: IAddOn[]) => {
				if (addOn.length > 0) {
					this.isLoading = false;
				}
				this.addOnDataSource = new MatTableDataSource<IAddOn>(addOn);
				this.addOnDataSource.sortingDataAccessor = (
					sortAddOns: IAddOn,
					property: string
				): string | number => {
					const propPath: string[] = property.split('.');
					const value: string = propPath.reduce(
						(curObj: IAddOn, prop: string) => {
							return curObj[prop];
						},
						sortAddOns
					);

					return value;
				};
				this.addOnDataSource.sort = this.sort;
				this.addOnDataSource.paginator = this.paginator;
			}
		);
	}
}
