<div class="editGroupModal">
	<h1 mat-dialog-title>Gruppe {{ group.graphGroup.displayName }} bearbeiten</h1>

	<form name="editForm">
		<div class="edit-form" mat-dialog-content>
			<mat-form-field appearance="outline">
				<mat-label>Display Name</mat-label>
				<input matInput placeholder="Name" [formControl]="nameCtrl" required />
				<mat-hint>Dieser Name wird angezeigt</mat-hint>
			</mat-form-field>

			<ng-container
				*ngIf="this.companies?.length > 0 && this.licenses?.length > 0"
			>
				<mat-form-field appearance="outline">
					<mat-label>Mandant</mat-label>
					<input
						matInput
						placeholder="Mandant"
						[matAutocomplete]="auto"
						[formControl]="companyCtrl"
						required
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="companyDisplayFn"
					>
						<mat-option
							*ngFor="let company of this.companies"
							[value]="company"
						>
							<span>{{ company.name }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint>Mandant für Snipe</mat-hint>
				</mat-form-field>
			</ng-container>

			<ng-container
				*ngIf="this.companies?.length > 0 && this.licenses?.length > 0"
			>
				<mat-form-field appearance="outline">
					<mat-label>Lizenz</mat-label>
					<input
						matInput
						placeholder="Lizenz"
						[matAutocomplete]="auto"
						[formControl]="licenseCtrl"
						required
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="licenseDisplayFn"
					>
						<mat-option *ngFor="let license of this.licenses" [value]="license">
							<span>{{ license.name }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint>Lizenz für die Gruppe</mat-hint>
				</mat-form-field>
			</ng-container>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				mat-raised-button
				[disabled]="editForm.invalid"
				color="primary"
				(click)="onConfirm()"
			>
				Speichern
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
