import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiRouteService } from 'src/app/_services/license/apiroute.service';
import { IApiRoute, IApiRouteRequest } from 'src/app/_models/apiroute';

/**
 * EditApiRouteComponent is responsible for updating a
 * FrontendRoute
 */
@Component({
	selector: 'app-edit-api-route',
	templateUrl: './edit-api-route.component.html',
	styleUrls: ['./edit-api-route.component.scss']
})
export class EditApiRouteComponent {
	public methodCtrl: FormControl = new FormControl();
	public routeCtrl: FormControl = new FormControl();
	public descriptionCtrl: FormControl = new FormControl();
	public editForm: FormGroup = new FormGroup({
		routeCtrl: this.routeCtrl,
		methodCtrl: this.methodCtrl,
		descriptionCtrl: this.descriptionCtrl
	});

	constructor(
		private readonly apiRouteService: ApiRouteService,
		public dialogRef: MatDialogRef<EditApiRouteComponent>,
		@Inject(MAT_DIALOG_DATA) public apiRoute: IApiRoute
	) {
		this.routeCtrl.setValue(apiRoute.route);
		this.methodCtrl.setValue(apiRoute.method);
		this.descriptionCtrl.setValue(apiRoute.description);
	}

	public async onConfirm(): Promise<void> {
		if (!this.editForm.valid) {
			this.dialogRef.close(false);
		}

		if (
			this.routeCtrl.value !== this.apiRoute.route ||
			this.methodCtrl.value !== this.apiRoute.method ||
			this.descriptionCtrl.value !== this.apiRoute.description
		) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	public async submit(): Promise<void> {
		const patchBody: IApiRouteRequest = {
			route: this.routeCtrl.value,
			method: this.methodCtrl.value,
			description: this.descriptionCtrl.value
		};

		await this.apiRouteService.patchApiRoute(patchBody, this.apiRoute.id);
	}
}
