<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="licenseDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="license.id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Parse Id</th>
			<td mat-cell *matCellDef="let license">{{ license.id }}</td>
		</ng-container>

		<!-- Route Column -->
		<ng-container matColumnDef="license.name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name der Lizenz</th>
			<td mat-cell *matCellDef="let license">{{ license.name }}</td>
		</ng-container>

		<!-- AddOns Column -->
		<ng-container matColumnDef="license.addOns">
			<th mat-header-cell *matHeaderCellDef>API-Routen</th>
			<td mat-cell *matCellDef="let license">
				<mat-chip-list>
					<mat-chip
						class="chip-style"
						color="primary"
						(click)="openAddOnSelection(license)"
						selected
					>
						{{ license.addOns.length }}</mat-chip
					>
				</mat-chip-list>
			</td>
		</ng-container>

		<!-- Is active Column -->
		<ng-container matColumnDef="license.active">
			<th mat-header-cell *matHeaderCellDef>Lizenz ist aktiviert</th>
			<td mat-cell *matCellDef="let license" class="checkbox-cell">
				<mat-checkbox
					[checked]="license.active"
					(change)="updateActiveState($event, license)"
				>
				</mat-checkbox>
			</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit" class="edit-column">
			<th mat-header-cell class="column-right-align" *matHeaderCellDef>
				Optionen
			</th>
			<td mat-cell class="column-right-align" *matCellDef="let license">
				<div class="button-div">
					<button
						mat-raised-button
						color="primary"
						(click)="openEditModal(license)"
					>
						<mat-icon>edit</mat-icon>
					</button>
					<button
						class="delete-btn"
						mat-raised-button
						color="warn"
						(click)="openDeleteModal(license.id)"
					>
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let license; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

	<button class="add-btn" mat-fab color="primary" (click)="openCreateModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>
