import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
interface IHttpOptions {
	headers: HttpHeaders;
}

/**
 * Http Service Provides generic funcitons for http calls
 *
 * @export
 * @class HttpService
 */
@Injectable({
	providedIn: 'root'
})
export class HttpService {
	public httpOptions: IHttpOptions = {
		headers: new HttpHeaders({
			Accept: 'application/json',
			'Content-Type': 'application/json'
		})
	};

	constructor(private readonly http: HttpClient) {}

	/**
	 * Generic Get-Request. Can be used to build specific Http-Services like the groups-service.
	 * The Return-Type can be specified by the  calling method
	 *
	 * @param url The url for the request
	 * @param idToken The idToken of the user
	 * @param accessToken The accessToken for the active directory
	 * @returns T
	 */
	public getData<T>(
		url: string,
		idToken: string,
		accessToken: string
	): Observable<T> {
		this.httpOptions.headers = this.httpOptions.headers.set(
			'Authorization',
			'Bearer ' + idToken
		);

		this.httpOptions.headers = this.httpOptions.headers.set(
			'X-Access-Token',
			accessToken
		);

		return this.http.get<T>(url, this.httpOptions).pipe(
			map((data: T) => {
				return data;
			})
		);
	}

	/**
	 * Generic Post-Request. Can be used to build specific Http-Services like the groups-service
	 * The Return-Type and the Body-Type can be specified by the calling method
	 *
	 * @param url The url for the request
	 * @param body The body for the request
	 * @param idToken The idToken of the user
	 * @param accessToken The accessToken for the active directory
	 * @returns T
	 */
	public postData<T>(
		url: string,
		body: T,
		idToken: string,
		accessToken: string
	): Observable<void> {
		this.httpOptions.headers = this.httpOptions.headers.set(
			'Authorization',
			'Bearer ' + idToken
		);

		this.httpOptions.headers = this.httpOptions.headers.set(
			'X-Access-Token',
			accessToken
		);

		return this.http.post<void>(url, body, this.httpOptions);
	}

	/**
	 * Generic Patch-Request. Can be used to build specific Http-Services like the groups-service
	 * The Return-Type and the Body-Type can be specified by the calling method
	 *
	 * @param url The url for the request
	 * @param body The body for the request
	 * @param idToken The idToken of the user
	 * @param accessToken The accessToken for the active directory
	 * @returns T
	 */
	public patchData<T>(
		url: string,
		body: T,
		idToken: string,
		accessToken: string
	): Observable<void> {
		this.httpOptions.headers = this.httpOptions.headers.set(
			'Authorization',
			'Bearer ' + idToken
		);

		this.httpOptions.headers = this.httpOptions.headers.set(
			'X-Access-Token',
			accessToken
		);

		return this.http.patch<void>(url, body, this.httpOptions);
	}

	/**
	 * Generic Delete-Request. Can be used to build specific Http-Services like the groups-service.
	 *
	 * @param url The url for the request
	 * @param idToken The idToken of the user
	 * @param accessToken The accessToken for the active directory
	 */
	public deleteData(
		url: string,
		idToken: string,
		accessToken: string
	): Observable<void> {
		this.httpOptions.headers = this.httpOptions.headers.set(
			'Authorization',
			'Bearer ' + idToken
		);

		this.httpOptions.headers = this.httpOptions.headers.set(
			'X-Access-Token',
			accessToken
		);

		return this.http.delete<void>(url, this.httpOptions);
	}
}
