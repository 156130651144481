<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="groupsDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="graphGroup.id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Group Id</th>
			<td mat-cell *matCellDef="let group">{{ group.graphGroup.id }}</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="graphGroup.displayName">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let group">
				{{ group.graphGroup.displayName }}
			</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef>Optionen</th>
			<td mat-cell *matCellDef="let group">
				<button
					mat-raised-button
					color="primary"
					(click)="
						openCreateMapping(group.graphGroup.id, group.graphGroup.displayName)
					"
				>
					<mat-icon>add</mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
