import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { IGroupRequest, IGroupResponse } from 'src/app/_models/groups';
import { FormControl, FormGroup } from '@angular/forms';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { ICompany } from 'src/app/_models/company';
import { CompanyService } from 'src/app/_services/company.service';
import { Subscription } from 'rxjs';
import { ILicense } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

/**
 * Edit Group Modal
 *
 * @export
 * @class EditGroupModalComponent
 */
@Component({
	selector: 'app-edit-group-modal',
	templateUrl: './edit-group-modal.component.html',
	styleUrls: ['./edit-group-modal.component.scss']
})
export class EditGroupModalComponent implements OnInit {
	public nameCtrl: FormControl = new FormControl();
	public descriptionCtrl: FormControl = new FormControl();
	public companyCtrl: FormControl = new FormControl();
	public licenseCtrl: FormControl = new FormControl();
	public editForm: FormGroup = new FormGroup({
		nameCtrl: this.nameCtrl,
		descriptionCtrl: this.descriptionCtrl,
		companyCtrl: this.companyCtrl,
		licenseCtrl: this.licenseCtrl
	});

	public companies: ICompany[] = [];
	public licenses: ILicense[] = [];
	private companySubscription: Subscription;
	private licenseSubscription: Subscription;

	constructor(
		public dialogRef: MatDialogRef<EditGroupModalComponent>,
		private readonly groupService: GroupsService,
		private readonly companyService: CompanyService,
		private readonly licenseService: LicenseService,
		@Inject(MAT_DIALOG_DATA) public group: IGroupResponse
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.companyService.getCompanies();
		await this.licenseService.getLicense();
		this.nameCtrl.setValue(this.group.graphGroup.displayName);
		this.descriptionCtrl.setValue(this.group.graphGroup.description);
		this.licenseCtrl.setValue(this.group.groupMapping.license);
	}

	public ngOnDestroy(): void {
		this.companySubscription.unsubscribe();
		this.licenseSubscription.unsubscribe();
	}

	public async onConfirm(): Promise<void> {
		if (this.editForm.valid && this.valuesChanged()) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	public companyDisplayFn(company: ICompany): string {
		return company ? company.name + ' - ' + company.id : '';
	}

	public licenseDisplayFn(license: ILicense): string {
		return license ? license.name + ' - ' + license.id : '';
	}

	private valuesChanged(): boolean {
		const companyId: string = this.companyCtrl.value.id.toString();
		if (
			this.nameCtrl.value !== this.group.graphGroup.displayName ||
			this.descriptionCtrl.value !== this.group.graphGroup.description ||
			companyId !== this.group.groupMapping.mandantenId ||
			this.licenseCtrl.value.id !== this.group.groupMapping.license.id
		) {
			return true;
		}

		return false;
	}

	private async submit(): Promise<void> {
		const companyId: string = this.companyCtrl.value.id.toString();
		const description: string | undefined =
			this.descriptionCtrl.value !== this.group.graphGroup.description
				? this.descriptionCtrl.value
				: undefined;
		const displayName: string | undefined =
			this.nameCtrl.value !== this.group.graphGroup.displayName
				? this.nameCtrl.value
				: undefined;
		const graphGroup: MicrosoftGraph.Group | undefined =
			description === undefined && displayName === undefined
				? undefined
				: {
						description: description,
						displayName: displayName
				  };

		const patchBody: IGroupRequest = {
			graphGroup: graphGroup,
			license:
				this.licenseCtrl.value.id !== this.group.groupMapping.license.id
					? this.licenseCtrl.value.id
					: undefined,
			mandantenId:
				companyId !== this.group.groupMapping.mandantenId
					? companyId
					: undefined
		};

		await this.groupService.patchGroup(patchBody, this.group.graphGroup.id);
	}

	private async initSubscriptions(): Promise<void> {
		this.companySubscription = this.companyService.companies.subscribe(
			(companies: ICompany[]) => {
				this.companies = companies;

				if (this.companies?.length > 0) {
					const company: ICompany = this.companies.find(
						(currentCompany: ICompany) => {
							return (
								currentCompany.id.toString() ===
								this.group.groupMapping.mandantenId
							);
						}
					);
					this.companyCtrl.setValue(company);
				}
			}
		);

		this.licenseSubscription = this.licenseService.license.subscribe(
			(licenses: ILicense[]) => {
				this.licenses = licenses;
			}
		);
	}
}
