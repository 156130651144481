<div class="createMappingModal">
	<h1 mat-dialog-title>Mappings für Group: {{ data.name }}</h1>

	<form name="createMappingForm">
		<div class="create-mapping-form" mat-dialog-content>
			<ng-container *ngIf="this.companies?.length > 0">
				<mat-form-field appearance="outline">
					<mat-label>Mandant</mat-label>
					<input
						matInput
						placeholder="Mandant"
						[matAutocomplete]="auto"
						[formControl]="companyCtrl"
						required
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="companyDisplayFn"
					>
						<mat-option
							*ngFor="let company of this.companies"
							[value]="company"
						>
							<span>{{ company.name }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint>Mandant für Snipe</mat-hint>
				</mat-form-field>
			</ng-container>

			<ng-container *ngIf="this.licenses?.length > 0">
				<mat-form-field appearance="outline">
					<mat-label>Lizenz</mat-label>
					<input
						matInput
						placeholder="Lizenz"
						[matAutocomplete]="auto"
						[formControl]="licenseCtrl"
						required
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="licenseDisplayFn"
					>
						<mat-option *ngFor="let license of this.licenses" [value]="license">
							<span>{{ license.name }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint>Lizenz für die Gruppe</mat-hint>
				</mat-form-field>
			</ng-container>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				mat-raised-button
				color="primary"
				[disabled]="createMappingForm.invalid"
				(click)="onConfirm()"
			>
				Speichern
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
