<div>
	<h1 mat-dialog-title>
		API-Routen für AddOn "{{ this.addOn.name }}" bearbeiten
	</h1>

	<mat-dialog-content>
		<mat-selection-list
			*ngIf="this.apiRoutes?.length > 0"
			[(ngModel)]="selectedApiRoutes"
			[compareWith]="compareFunction"
		>
			<mat-list-option
				class="selection-option stock-hover-option"
				*ngFor="let route of apiRoutes"
				[value]="route"
			>
				<h4>
					{{ route.route }} ({{ route.method }}) ({{ route.description }})
				</h4>
			</mat-list-option>
		</mat-selection-list>
	</mat-dialog-content>

	<div mat-dialog-actions>
		<button mat-raised-button color="primary" (click)="onConfirm()">
			Speichern
		</button>
		<button mat-raised-button color="secondary" (click)="onClose()">
			Schließen
		</button>
	</div>
</div>
