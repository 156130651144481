import { NgModule } from '@angular/core';

import { LayoutModule } from '@angular/cdk/layout';

//
//Form Controls
//

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

//import { MatNativeDateModule } from '@angular/material'
//import { MatMomentDateModule } from '@angular/material-moment-adapter';

//
//Navigation
//

import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

//
//Layout
//

import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';

//
//Buttons & Indicators
//

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

//
//Popups & Modals
//

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

//
//Data Table
//

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const modules: any[] = [
	LayoutModule,

	MatAutocompleteModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatFormFieldModule,
	MatInputModule,
	MatRadioModule,
	MatSelectModule,
	MatSliderModule,
	MatSlideToggleModule,

	//MatNativeDateModule,
	//MatMomentDateModule,

	MatMenuModule,
	MatSidenavModule,
	MatToolbarModule,

	MatCardModule,
	MatDividerModule,
	MatExpansionModule,
	MatGridListModule,
	MatListModule,
	MatStepperModule,
	MatTabsModule,
	MatTreeModule,

	MatButtonModule,
	MatButtonToggleModule,
	MatBadgeModule,
	MatChipsModule,
	MatIconModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,

	MatBottomSheetModule,
	MatDialogModule,
	MatSnackBarModule,
	MatTooltipModule,

	MatPaginatorModule,
	MatSortModule,
	MatTableModule
];
/**
 * Module für Angular Material
 *
 * @export
 * @class AngularMaterialModule
 */
@NgModule({
	imports: [...modules],
	exports: [...modules]
})
export class AngularMaterialModule {}
