import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILicense, ILicenseRequest } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

/**
 * Edit remaining fields of License
 */
@Component({
	selector: 'app-edit-license',
	templateUrl: './edit-license.component.html',
	styleUrls: ['./edit-license.component.scss']
})
export class EditLicenseComponent {
	public nameCtrl: FormControl = new FormControl();
	public editForm: FormGroup = new FormGroup({
		nameCtrl: this.nameCtrl
	});

	constructor(
		private readonly licenseService: LicenseService,
		public dialogRef: MatDialogRef<EditLicenseComponent>,
		@Inject(MAT_DIALOG_DATA) public license: ILicense
	) {
		this.nameCtrl.setValue(license.name);
	}

	public async onConfirm(): Promise<void> {
		if (this.editForm.valid && this.nameCtrl.value !== this.license.name) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	public async submit(): Promise<void> {
		const patchBody: ILicenseRequest = {
			name: this.nameCtrl.value,
			addOns: undefined,
			removeAddOn: undefined,
			active: undefined
		};

		await this.licenseService.patchLicense(patchBody, this.license.id);
	}
}
