<div class="createUserModal">
	<h1 mat-dialog-title>
		Neuen User anlegen
	</h1>

	<form name="createForm">
		<div class="create-form" mat-dialog-content>
			<mat-form-field appearance="outline">
				<mat-label>Display Name</mat-label>
				<input matInput placeholder="Name" [formControl]="nameCtrl" required />
				<mat-hint>Dieser Name wird angezeigt</mat-hint>
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-label>Mail-Nickname</mat-label>
				<input
					matInput
					placeholder="Mail-Nickname"
					[formControl]="mailCtrl"
					required
				/>
				<mat-hint>Prefix für die E-Mail</mat-hint>
			</mat-form-field>

			<ng-container
				*ngIf="this.groups?.length > 0 && this.licenses?.length > 0"
			>
				<mat-form-field appearance="outline">
					<mat-label>Gruppe</mat-label>
					<input
						matInput
						placeholder="Gruppe"
						[matAutocomplete]="auto"
						[formControl]="groupCtrl"
						required
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="groupDisplayFn"
					>
						<mat-option *ngFor="let group of this.groups" [value]="group">
							<span>{{ group.graphGroup.displayName }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint
						>Azuregruppe, welcher der Nutzer in der Anwendung zugeordnet werden
						soll</mat-hint
					>
				</mat-form-field>
			</ng-container>

			<ng-container
				*ngIf="this.groups?.length > 0 && this.licenses?.length > 0"
			>
				<mat-form-field appearance="outline">
					<mat-label>Lizenz</mat-label>
					<input
						matInput
						placeholder="Lizenz"
						[matAutocomplete]="auto"
						[formControl]="licenseCtrl"
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="licenseDisplayFn"
					>
						<mat-option *ngFor="let license of this.licenses" [value]="license">
							<span>{{ license.name }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint>Dedizierte Lizenz für den User</mat-hint>
				</mat-form-field>
			</ng-container>

			<mat-form-field appearance="outline">
				<mat-label>Password</mat-label>
				<!--TODO: Regex for password validation-->
				<input
					matInput
					placeholder="Initiales Password"
					[formControl]="passwordCtrl"
					required
				/>
				<mat-hint>Initiales Passwort für den User</mat-hint>
			</mat-form-field>

			<div class="checkbox-div">
				<mat-checkbox [formControl]="passwordChangeCtrl"
					>Passwort ändern beim nächsten Login</mat-checkbox
				>
			</div>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				mat-raised-button
				[disabled]="createForm.invalid"
				color="primary"
				(click)="onConfirm()"
			>
				Speichern
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
