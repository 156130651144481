import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { UsersService } from '../_services/users/users.service';
import { IUserResponse } from '../_models/users';
import { EditUserModalComponent } from '../_components/user/edit-user-modal/edit-user-modal.component';
import { DeleteUserModalComponent } from '../_components/user/delete-user-modal/delete-user-modal.component';
import { CreateUserModalComponent } from '../_components/user/create-user-modal/create-user-modal.component';
import { InviteUserModalComponent } from '../_components/user/invite-user-modal/invite-user-modal.component';
import { SendInvitationModalComponent } from '../_components/user/send-invitation-modal/send-invitation-modal.component';

/**
 * User Component
 *
 * @export
 * @class UsersComponent
 */
@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
	public initalData: IUserResponse[] = [];
	public usersDataSource: MatTableDataSource<
		IUserResponse
	> = new MatTableDataSource(this.initalData);
	public displayedColumns: string[] = [
		'graphUser.id',
		'userMapping.group.groupName',
		'graphUser.displayName',
		'graphUser.userPrincipalName',
		'userMapping.license',
		'edit'
	];
	public isLoading: boolean = true;

	private usersSub: Subscription;

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly usersService: UsersService,
		public readonly dialog: MatDialog,
		private readonly breakpointObserver: BreakpointObserver
	) {
		breakpointObserver
			.observe(['(max-width: 1300px)'])
			.subscribe((result: BreakpointState) => {
				if (result.matches) {
					this.displayedColumns = [
						'graphUser.id',
						'userMapping.group.groupName',
						'graphUser.displayName',
						'userMapping.license',
						'edit'
					];
				} else {
					this.displayedColumns = [
						'graphUser.id',
						'userMapping.group.groupName',
						'graphUser.displayName',
						'graphUser.userPrincipalName',
						'userMapping.license',
						'edit'
					];
				}
			});
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.usersService.getUsers();
	}

	public ngOnDestroy(): void {
		this.usersSub.unsubscribe();
	}

	public async openCreateModal(): Promise<void> {
		const dialogRef: MatDialogRef<CreateUserModalComponent> = this.dialog.open(
			CreateUserModalComponent,
			{
				panelClass: 'custom-modalbox'
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.usersService.getUsers();
			}, 1000);
		}
	}

	public async openInviteModal(): Promise<void> {
		const dialogRef: MatDialogRef<InviteUserModalComponent> = this.dialog.open(
			InviteUserModalComponent,
			{
				panelClass: 'custom-modalbox'
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.usersService.getUsers();
			}, 2000);
		}
	}

	public async openEditModal(user: IUserResponse): Promise<void> {
		const dialogRef: MatDialogRef<EditUserModalComponent> = this.dialog.open(
			EditUserModalComponent,
			{
				panelClass: 'custom-modalbox',
				data: user
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.usersService.getUsers();
			}, 2000);
		}
	}

	public async openDeleteModal(id: string): Promise<void> {
		const dialogRef: MatDialogRef<DeleteUserModalComponent> = this.dialog.open(
			DeleteUserModalComponent,
			{
				panelClass: 'custom-modalbox',
				data: id
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.usersService.getUsers();
		}
	}

	public async sendInvitation(id: string): Promise<void> {
		const dialogRef: MatDialogRef<SendInvitationModalComponent> = this.dialog.open(
			SendInvitationModalComponent,
			{
				panelClass: 'custom-modalbox',
				data: id
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.usersService.getUsers();
		}
	}

	//Initialization of User-Observable
	private initSubscriptions(): void {
		this.usersSub = this.usersService.users.subscribe(
			(users: IUserResponse[]) => {
				if (users.length > 0) {
					this.isLoading = false;
				}
				this.usersDataSource = new MatTableDataSource<IUserResponse>(users);
				this.usersDataSource.sortingDataAccessor = (
					sortGroups: IUserResponse,
					property: string
				): string | number => {
					const propPath: string[] = property.split('.');
					const value: string = propPath.reduce(
						(curObj: IUserResponse, prop: string) => {
							return curObj[prop];
						},
						sortGroups
					);

					return value;
				};
				this.usersDataSource.sort = this.sort;
				this.usersDataSource.paginator = this.paginator;
			}
		);
	}
}
