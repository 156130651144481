<div class="deleteApiRouteModal">
	<h1 mat-dialog-title>
		API-Route löschen
	</h1>

	<form name="deleteForm">
		<div class="delete-form" mat-dialog-content>
			<p>Geben Sie zum Löschen die Id der Route ein:</p>
			<mat-form-field appearance="outline">
				<input
					matInput
					placeholder="Id der zu löschenden Route"
					[formControl]="idCtrl"
					required
				/>
			</mat-form-field>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				mat-raised-button
				color="warn"
				(click)="onConfirm()"
				[disabled]="deleteForm.invalid"
			>
				Löschen
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
