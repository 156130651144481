import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import IFrontendRoute, {
	IFrontendRouteRequest
} from '../../_models/frontendroute';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';

/**
 * Frontend Route Service for fetching and updating data
 *
 * @export
 * @class FrontendRouteService
 */
@Injectable({
	providedIn: 'root'
})
export class FrontendRouteService {
	public readonly frontendRoutes: Observable<IFrontendRoute[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _frontendRouteSubject: BehaviorSubject<
		IFrontendRoute[]
	> = new BehaviorSubject<IFrontendRoute[]>({} as IFrontendRoute[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.frontendRoutes = this._frontendRouteSubject.asObservable();
	}

	public async getFrontendRoutes(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/frontendroute';
		this.httpService
			.getData<IFrontendRoute[]>(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(frontendRoutes: IFrontendRoute[]) => {
					this._frontendRouteSubject.next(frontendRoutes);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createFrontendRoute(body: IFrontendRouteRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/frontendroute';
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Route wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen der Route fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async patchFrontendRoute(
		body: IFrontendRouteRequest,
		id: string
	): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/frontendroute/${id}`;
		this.httpService
			.patchData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Route wurde erfolgreich geupdated');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Updaten der Route fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async deleteFrontendRoute(id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/frontendroute/${id}`;
		this.httpService
			.deleteData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Route wurde erfolgreich gelöscht');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Löschen der Route fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}
}
