<div class="inviteModal">
	<h1 mat-dialog-title>
		User einladen
	</h1>

	<form name="inviteForm">
		<div class="invite-form" mat-dialog-content>
			<mat-form-field appearance="outline">
				<mat-label>E-Mail</mat-label>
				<input
					matInput
					type="email"
					placeholder="E-Mail"
					[formControl]="emailCtrl"
					email
					required
				/>
				<mat-hint>E-Mail des Nutzers, der eingeladen werden soll</mat-hint>
			</mat-form-field>

			<ng-container *ngIf="this.groups?.length > 0">
				<mat-form-field appearance="outline">
					<mat-label>Gruppe</mat-label>
					<input
						matInput
						placeholder="Gruppe"
						[matAutocomplete]="auto"
						[formControl]="groupCtrl"
						required
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="groupDisplayFn"
					>
						<mat-option *ngFor="let group of this.groups" [value]="group">
							<span>{{ group.graphGroup.displayName }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint
						>Azuregruppe, welcher der Nutzer in der Anwendung zugeordnet werden
						soll</mat-hint
					>
				</mat-form-field>
			</ng-container>

			<ng-container
				*ngIf="this.groups?.length > 0 && this.licenses?.length > 0"
			>
				<mat-form-field appearance="outline">
					<mat-label>Lizenz</mat-label>
					<input
						matInput
						placeholder="Lizenz"
						[matAutocomplete]="auto"
						[formControl]="licenseCtrl"
					/>
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="licenseDisplayFn"
					>
						<mat-option *ngFor="let license of this.licenses" [value]="license">
							<span>{{ license.name }}</span>
						</mat-option>
					</mat-autocomplete>
					<mat-hint>Dedizierte Lizenz für den User</mat-hint>
				</mat-form-field>
			</ng-container>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				[disabled]="inviteForm.invalid"
				mat-raised-button
				color="primary"
				(click)="onConfirm()"
			>
				Speichern
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
