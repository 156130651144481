<div>
	<h1 mat-dialog-title>
		Neue Lizenz erstellen
	</h1>

	<mat-dialog-content>
		<form name="createAddOnForm">
			<mat-horizontal-stepper class="stepper" labelPosition="bottom" #stepper>
				<mat-step>
					<ng-template matStepLabel>Namen für die Lizenz angeben</ng-template>
					<div>
						<mat-form-field appearance="outline">
							<mat-label>Lizenz</mat-label>
							<input
								[formControl]="nameCtrl"
								matInput
								placeholder="Name"
								required
							/>
							<mat-hint>Name für die Lizenz</mat-hint>
						</mat-form-field>
					</div>
					<button
						[disabled]="this.nameCtrl.invalid"
						mat-raised-button
						color="primary"
						matStepperNext
					>
						Weiter
					</button>
				</mat-step>
				<mat-step optional>
					<ng-template matStepLabel>AddOns auswählen</ng-template>
					<mat-dialog-content class="selection-dialog">
						<mat-selection-list
							*ngIf="this.addOns?.length > 0"
							[(ngModel)]="selectedAddOns"
							name="addOns"
							[compareWith]="compareFunction"
						>
							<mat-list-option
								class="selection-option stock-hover-option"
								*ngFor="let addOn of addOns"
								[value]="addOn"
							>
								{{ addOn.name }}
							</mat-list-option>
						</mat-selection-list>
					</mat-dialog-content>
					<div>
						<button mat-raised-button color="secondary" matStepperPrevious>
							Zurück
						</button>
						<button mat-raised-button color="primary" matStepperNext>
							Weiter
						</button>
					</div>
				</mat-step>
				<mat-step>
					<ng-template matStepLabel>Zusammenfassung</ng-template>

					<h3>Name:</h3>
					<p>{{ this.nameCtrl.value }}</p>

					<div *ngIf="this.selectedAddOns?.length > 0">
						<h3>Ausgewählte AddOns:</h3>
						<mat-dialog-content>
							<mat-list>
								<mat-list-item
									class="selection-option stock-hover-item"
									*ngFor="let addOn of selectedAddOns"
								>
									<p>{{ addOn.name }}</p>
								</mat-list-item>
							</mat-list>
						</mat-dialog-content>
					</div>

					<div>
						<button mat-raised-button color="primary" (click)="onConfirm()">
							Speichern
						</button>
						<button mat-raised-button color="secondary" matStepperPrevious>
							Zurück
						</button>
						<button mat-raised-button color="secondary" (click)="onClose()">
							Schließen
						</button>
					</div>
				</mat-step>
			</mat-horizontal-stepper>
		</form>
	</mat-dialog-content>
</div>
