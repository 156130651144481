import { Component, OnInit, Inject } from '@angular/core';
import { GroupsMigrationService } from 'src/app/_services/groups/groups-migration.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IGroupRequest } from 'src/app/_models/groups';
import { FormControl, FormGroup } from '@angular/forms';
import { ICompany } from 'src/app/_models/company';
import { Subscription } from 'rxjs';
import { CompanyService } from 'src/app/_services/company.service';
import { ILicense } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

interface ICreateInformation {
	id: string;
	name: string;
}

/**
 * Create Group Mapping Component
 *
 * @export
 * @class CreateGroupMappingComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'app-create-group-mapping-modal',
	templateUrl: './create-group-mapping-modal.component.html',
	styleUrls: ['./create-group-mapping-modal.component.scss']
})
export class CreateGroupMappingModalComponent implements OnInit {
	public companyCtrl: FormControl = new FormControl();
	public licenseCtrl: FormControl = new FormControl();
	public createMappingForm: FormGroup = new FormGroup({
		companyCtrl: this.companyCtrl,
		licenseCtrl: this.licenseCtrl
	});

	public companies: ICompany[] = [];
	public licenses: ILicense[] = [];
	private companySubscription: Subscription;
	private licenseSubscription: Subscription;

	constructor(
		public companyService: CompanyService,
		public licenseService: LicenseService,
		public groupsMigrationService: GroupsMigrationService,
		public dialogRef: MatDialogRef<CreateGroupMappingModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ICreateInformation
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.companyService.getCompanies();
		await this.licenseService.getLicense();
	}

	public ngOnDestroy(): void {
		this.companySubscription.unsubscribe();
		this.licenseSubscription.unsubscribe();
	}

	public companyDisplayFn(company: ICompany): string {
		return company ? company.name + ' - ' + company.id : '';
	}

	public licenseDisplayFn(license: ILicense): string {
		return license ? license.name + ' - ' + license.id : '';
	}

	public async onConfirm(): Promise<void> {
		if (this.createMappingForm.valid) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(): Promise<void> {
		const companyId: string = this.companyCtrl.value.id.toString();
		const licenseId: string = this.licenseCtrl.value.id;
		const body: IGroupRequest = {
			graphGroup: undefined,
			mandantenId: companyId,
			license: licenseId
		};

		await this.groupsMigrationService.createMapping(this.data.id, body);
	}

	private async initSubscriptions(): Promise<void> {
		this.companySubscription = this.companyService.companies.subscribe(
			(companies: ICompany[]) => {
				this.companies = companies;
			}
		);

		this.licenseSubscription = this.licenseService.license.subscribe(
			(licenses: ILicense[]) => {
				this.licenses = licenses;
			}
		);
	}
}
