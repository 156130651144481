import {
	Component,
	Output,
	EventEmitter,
	ChangeDetectorRef
} from '@angular/core';
import {
	BreakpointObserver,
	Breakpoints,
	BreakpointState
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/authentication.service';
/**
 * Top Bar Component
 *
 * @export
 * @class NavigationBarComponent
 */
@Component({
	selector: 'app-navigation-bar',
	templateUrl: './navigation-bar.component.html',
	styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {
	@Output() public toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

	public isHandset$: Observable<boolean> = this.breakpointObserver
		.observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait])
		.pipe(
			map((result: BreakpointState) => {
				return result.matches;
			}),
			tap(() => {
				return this.changeDetectorRef.detectChanges();
			})
		);

	constructor(
		public readonly authenticationService: AuthenticationService,
		private readonly breakpointObserver: BreakpointObserver,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {}
}
