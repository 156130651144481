<app-navigation-bar (toggleSidenav)="navigationSidenav.toggle()">
</app-navigation-bar>

<mat-sidenav-container autosize>
	<mat-sidenav
		#navigationSidenav
		[mode]="!(isHandset$ | async) ? 'side' : 'over'"
		[opened]="
			!(isHandset$ | async) && this.authenticationService.isAuthenticated()
		"
		[attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
	>
		<mat-nav-list>
			<ng-container>
				<h2 matSubheader class="list-headers">
					<span class="list-headers">Verwaltung</span>
				</h2>

				<a
					mat-list-item
					[routerLink]="['/users']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						Nutzer
					</span>
				</a>

				<a
					mat-list-item
					[routerLink]="['/groups']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						Gruppen
					</span>
				</a>

				<a
					mat-list-item
					[routerLink]="['/license']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						Lizenzen
					</span>
				</a>

				<a
					mat-list-item
					[routerLink]="['/addon']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						AddOn
					</span>
				</a>

				<a
					mat-list-item
					[routerLink]="['/frontendroutes']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						Frontend-Routen
					</span>
				</a>

				<a
					mat-list-item
					[routerLink]="['/apiroutes']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						API-Routen
					</span>
				</a>
			</ng-container>

			<mat-divider></mat-divider>

			<ng-container>
				<h2 matSubheader class="list-headers">
					<span class="list-headers">Migration</span>
				</h2>

				<a
					mat-list-item
					[routerLink]="['/user-migration']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						Usermigration
					</span>
				</a>

				<a
					mat-list-item
					[routerLink]="['/group-migration']"
					routerLinkActive="router-link-active"
					(click)="isHandset && navigationSidenav.close()"
				>
					<span class="nav-caption">
						Gruppenmigration
					</span>
				</a>
			</ng-container>
		</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content role="main">
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
