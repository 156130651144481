<div class="editFrontendRouteModal">
	<h1 mat-dialog-title>
		Route bearbeiten
	</h1>

	<form name="editForm">
		<div class="edit-form" mat-dialog-content>
			<mat-form-field appearance="outline">
				<mat-label>Key</mat-label>
				<input matInput placeholder="Key" [formControl]="keyCtrl" required />
				<mat-hint
					>Key der definiert, welches Modul im Frontend geladen werden
					soll</mat-hint
				>
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-label>Beschreibung</mat-label>
				<input
					matInput
					placeholder="Beschreibung"
					[formControl]="descriptionCtrl"
					required
				/>
				<mat-hint>Breschreibung für das Modul</mat-hint>
			</mat-form-field>
		</div>

		<form name="editConfigForm">
			<div class="edit-route-entry">
				<h4>Eintrag zur Konfig hinzufügen</h4>
				<mat-form-field appearance="outline">
					<mat-label>Pfad</mat-label>
					<input
						matInput
						placeholder="Pfad"
						[formControl]="pathCtrl"
						required
					/>
					<mat-hint
						>Pfad über welchen das Modul im Frontend erreichbar ist</mat-hint
					>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Label Key</mat-label>
					<input
						matInput
						placeholder="Label Key"
						[formControl]="labelCtrl"
						required
					/>
					<mat-hint>LabelKey für die UI</mat-hint>
				</mat-form-field>

				<button
					mat-raised-button
					color="primary"
					[disabled]="this.editConfigForm.invalid"
					(click)="addConfig()"
				>
					<mat-icon>add</mat-icon>
				</button>
			</div>
		</form>

		<div *ngIf="configs.length > 0">
			<h4>Aktuelle Configs:</h4>
			<mat-dialog-content class="config-dialog">
				<mat-list>
					<mat-list-item
						*ngFor="let config of configs"
						class="config-list-item stock-hover-item"
					>
						<h4 class="label">
							Pfad: {{ config.path }}, Label: {{ config.label }}
						</h4>
						<button
							mat-raised-button
							color="warn"
							class="delete-btn"
							(click)="removeConfig(config)"
						>
							<mat-icon>delete</mat-icon>
						</button>
					</mat-list-item>
				</mat-list>
			</mat-dialog-content>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				mat-raised-button
				color="primary"
				[disabled]="editForm.invalid"
				(click)="onConfirm()"
			>
				Speichern
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
