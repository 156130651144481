import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddOnService } from 'src/app/_services/license/addon.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAddOn, IAddOnRequest } from 'src/app/_models/addon';

/**
 * Edits the additional information of the addon
 */
@Component({
	selector: 'app-edit-addon',
	templateUrl: './edit-addon.component.html',
	styleUrls: ['./edit-addon.component.scss']
})
export class EditAddonComponent {
	public nameCtrl: FormControl = new FormControl();
	public editForm: FormGroup = new FormGroup({
		nameCtrl: this.nameCtrl
	});

	constructor(
		private readonly addOnService: AddOnService,
		public dialogRef: MatDialogRef<EditAddonComponent>,
		@Inject(MAT_DIALOG_DATA) public addOn: IAddOn
	) {
		this.nameCtrl.setValue(addOn.name);
	}

	public async onConfirm(): Promise<void> {
		if (this.editForm.valid && this.nameCtrl.value !== this.addOn.name) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	public async submit(): Promise<void> {
		const patchBody: IAddOnRequest = {
			name: this.nameCtrl.value,
			apiRoutes: undefined,
			routes: undefined,
			remove: undefined
		};

		await this.addOnService.patchAddOn(patchBody, this.addOn.id);
	}
}
