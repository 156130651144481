import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as msal from '@azure/msal-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AlertService } from './alert.service';

/**
 * Handles Login / Logout & stores token & user information
 *
 * @export
 * @class AuthenticationService
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	public readonly currentUser: Observable<msal.AccountInfo>;
	public readonly accessToken: Observable<string>;
	public readonly idToken: Observable<string>;
	public readonly loggedIn: Observable<boolean>;

	private readonly _currentUserSubject: BehaviorSubject<msal.AccountInfo>;
	private readonly _accessTokenSubject: BehaviorSubject<
		string
	> = new BehaviorSubject<string>('');
	private readonly _idTokenSubject: BehaviorSubject<
		string
	> = new BehaviorSubject<string>('');
	private readonly _loggedInSubject: BehaviorSubject<
		boolean
	> = new BehaviorSubject<boolean>(false);

	private readonly aConsentScopes: string[] = environment.aConsentScopes;
	private readonly msalConfig: msal.Configuration = <msal.Configuration>(
		environment.msalConfig
	);

	private readonly msalInstance: msal.PublicClientApplication = new msal.PublicClientApplication(
		this.msalConfig
	);
	private readonly jwtHelper: JwtHelperService = new JwtHelperService();

	constructor(
		private readonly alertService: AlertService,
		private readonly router: Router
	) {
		this._currentUserSubject = new BehaviorSubject<msal.AccountInfo>(
			this.getAccount()
		);
		this.currentUser = this._currentUserSubject.asObservable();

		this.accessToken = this._accessTokenSubject.asObservable();
		this.idToken = this._idTokenSubject.asObservable();

		this.loggedIn = this._loggedInSubject.asObservable();

		this.getAccessToken();
	}
	/**
	 * Microsoft Login via Popup
	 *
	 * @returns {Promise<void>}
	 * @memberof AuthenticationService
	 */
	public async login(): Promise<void> {
		try {
			const loginResponse: msal.AuthenticationResult = await this.msalInstance.loginPopup(
				{
					scopes: this.aConsentScopes,
					prompt: 'select_account'
				}
			);

			await this.getUser(loginResponse.account);
			await this.getAccessToken();

			return;
		} catch (error) {
			this.alertService.error('Fehler beim Login');
		}
	}

	/**
	 * Microsoft Logout
	 *
	 * @returns {Promise<void>}
	 * @memberof AuthenticationService
	 */
	public async logout(): Promise<void> {
		try {
			await this.msalInstance.logout({ account: this.currentUserValue });
			this._loggedInSubject.next(false);
			this._currentUserSubject.next(undefined);
		} catch (error) {
			this._loggedInSubject.next(false);
			this._currentUserSubject.next(undefined);
			this.router.navigate(['/login']);
		}
	}

	/**
	 * Prüft ob ein Nutzer angemeldet ist
	 *
	 * @returns {boolean}
	 * @memberof AuthenticationService
	 */
	public isAuthenticated(): boolean {
		return !!this.currentUserValue;
	}

	/**
	 * Checkt ob die Tokens noch valide sind, und refresht diese falls nicht
	 *
	 * @memberof AuthenticationService
	 */
	public async checkAndRefreshToken(): Promise<void> {
		if (!this.checkIfTokensValid()) {
			await this.getAccessToken();
		}
	}

	/**
	 * Prüft ob die Tokens valide sind und die Form passt
	 * @memberof AuthenticationService
	 * @returns boolean
	 */
	private checkIfTokensValid(): boolean {
		try {
			const idValid: boolean = !this.jwtHelper.isTokenExpired(
				this.idTokenValue
			);
			const accessValid: boolean = !this.jwtHelper.isTokenExpired(
				this.accessTokenValue
			);

			if (idValid && accessValid) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	}

	private getAccount(): msal.AccountInfo | null {
		const currentAccounts: msal.AccountInfo[] = this.msalInstance.getAllAccounts();
		if (currentAccounts === null) {
			return;
		} else if (currentAccounts.length > 1) {
			return currentAccounts[0];
		} else if (currentAccounts.length === 1) {
			return currentAccounts[0];
		}
	}

	/**
	 * Holt den Microsoft AccessToken + Power BI
	 *
	 * @private
	 * @returns {Promise<void>}
	 * @memberof AuthenticationService
	 */
	private async getAccessToken(): Promise<void> {
		if (this.isAuthenticated()) {
			try {
				const authResponse: msal.AuthenticationResult = await this.msalInstance.acquireTokenSilent(
					{
						account: this.getAccount(),
						scopes: this.aConsentScopes
					}
				);
				this.accessTokenValue = authResponse.accessToken;
				this.idTokenValue = authResponse.idToken;
			} catch (error) {
				const authResponse: msal.AuthenticationResult = await this.msalInstance
					.acquireTokenPopup({
						scopes: this.aConsentScopes,
						prompt: 'select_account'
					})
					.catch((err: Error) => {
						return undefined;
					});

				if (authResponse === undefined) {
					this.logout();

					return;
				}
				this.accessTokenValue = authResponse.accessToken;
				this.idTokenValue = authResponse.idToken;
			}
		}
	}

	/**
	 * Setzt den aktuellen Nutzer
	 *
	 * @private
	 * @returns {Promise<void>}
	 * @memberof AuthenticationService
	 */
	private async getUser(user: msal.AccountInfo): Promise<void> {
		try {
			this.currentUserValue = this.msalInstance.getAccountByUsername(
				user.username
			);
		} catch (error) {
			this.alertService.error(error);
			this.currentUserValue = undefined;
		}
	}

	/**
	 * Getter für Accesstoken
	 *
	 * @type {string}
	 * @memberof AuthenticationService
	 */
	public get accessTokenValue(): string {
		return this._accessTokenSubject.value;
	}

	/**
	 * Setter für Accesstoken
	 *
	 * @type {string}
	 * @memberof AuthenticationService
	 */
	public set accessTokenValue(token: string) {
		this._accessTokenSubject.next(token);
	}

	/**
	 * Getter für Accesstoken
	 *
	 * @type {string}
	 * @memberof AuthenticationService
	 */
	public get idTokenValue(): string {
		return this._idTokenSubject.value;
	}

	/**
	 * Setter für Accesstoken
	 *
	 * @type {string}
	 * @memberof AuthenticationService
	 */
	public set idTokenValue(token: string) {
		this._idTokenSubject.next(token);
	}

	/**
	 * Getter für Current User
	 *
	 * @type {string}
	 * @memberof AuthenticationService
	 */
	public get currentUserValue(): msal.AccountInfo {
		return this._currentUserSubject.value;
	}

	/**
	 * Setter für Current User
	 *
	 * @type {string}
	 * @memberof AuthenticationService
	 */
	public set currentUserValue(user: msal.AccountInfo) {
		this._currentUserSubject.next(user);
	}
}
