import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IApiRoute, IApiRouteRequest } from 'src/app/_models/apiroute';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';

/**
 * API Route Service for fetching and updating data
 *
 * @export
 * @class ApiRouteService
 */
@Injectable({
	providedIn: 'root'
})
export class ApiRouteService {
	public readonly apiRoutes: Observable<IApiRoute[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _apiRouteSubject: BehaviorSubject<
		IApiRoute[]
	> = new BehaviorSubject<IApiRoute[]>({} as IApiRoute[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.apiRoutes = this._apiRouteSubject.asObservable();
	}

	public async getApiRoutes(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/apiroute';
		this.httpService
			.getData<IApiRoute[]>(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(apiRoutes: IApiRoute[]) => {
					this._apiRouteSubject.next(apiRoutes);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createApiRoute(body: IApiRouteRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/apiroute';
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Route wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen der Route fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async patchApiRoute(
		body: IApiRouteRequest,
		id: string
	): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/apiroute/${id}`;
		this.httpService
			.patchData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Route wurde erfolgreich geupdated');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Updaten der Route fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async deleteApiRoute(id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/apiroute/${id}`;
		this.httpService
			.deleteData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Route wurde erfolgreich gelöscht');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Löschen der Route fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}
}
