import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ILicense, ILicenseRequest } from '../_models/license';
import { LicenseService } from '../_services/license/license.service';
import { AddOnSelectorComponent } from '../_components/license/add-on-selector/add-on-selector.component';
import { DeleteLicenseComponent } from '../_components/license/delete-license/delete-license.component';
import { EditLicenseComponent } from '../_components/license/edit-license/edit-license.component';
import { CreateLicenseComponent } from '../_components/license/create-license/create-license.component';

/**
 * License component is responsible for managing all the licenses
 * of the portal
 */
@Component({
	selector: 'app-license',
	templateUrl: './license.component.html',
	styleUrls: ['./license.component.scss']
})
export class LicenseComponent {
	public initalData: ILicense[] = [];
	public licenseDataSource: MatTableDataSource<
		ILicense
	> = new MatTableDataSource(this.initalData);
	public displayedColumns: string[] = [
		'license.id',
		'license.name',
		'license.addOns',
		'license.active',
		'edit'
	];
	public isLoading: boolean = true;

	private licenseSubscription: Subscription;

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly licenseService: LicenseService,
		public readonly dialog: MatDialog
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.licenseService.getLicense();
	}

	public ngOnDestroy(): void {
		this.licenseSubscription.unsubscribe();
	}

	public async updateActiveState(
		event: MatCheckboxChange,
		license: ILicense
	): Promise<void> {
		const body: ILicenseRequest = {
			name: undefined,
			active: event.checked,
			addOns: undefined,
			removeAddOn: undefined
		};

		await this.licenseService.patchLicense(body, license.id);
	}

	public async openAddOnSelection(license: ILicense): Promise<void> {
		const dialogRef: MatDialogRef<AddOnSelectorComponent> = this.dialog.open(
			AddOnSelectorComponent,
			{
				panelClass: 'selection-modalbox',
				data: license
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.licenseService.getLicense();
		}
	}

	public async openCreateModal(): Promise<void> {
		const dialogRef: MatDialogRef<CreateLicenseComponent> = this.dialog.open(
			CreateLicenseComponent,
			{
				panelClass: 'custom-modalbox'
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.licenseService.getLicense();
			}, 1000);
		}
	}

	public async openEditModal(license: ILicense): Promise<void> {
		const dialogRef: MatDialogRef<EditLicenseComponent> = this.dialog.open(
			EditLicenseComponent,
			{
				panelClass: 'custom-modalbox',
				data: license
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.licenseService.getLicense();
		}
	}

	public async openDeleteModal(id: string): Promise<void> {
		const dialogRef: MatDialogRef<DeleteLicenseComponent> = this.dialog.open(
			DeleteLicenseComponent,
			{
				panelClass: 'custom-modalbox',
				data: id
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.licenseService.getLicense();
		}
	}

	private async initSubscriptions(): Promise<void> {
		this.licenseSubscription = this.licenseService.license.subscribe(
			(licenses: ILicense[]) => {
				if (licenses.length > 0) {
					this.isLoading = false;
				}
				this.licenseDataSource = new MatTableDataSource<ILicense>(licenses);
				this.licenseDataSource.sortingDataAccessor = (
					sortLicense: ILicense,
					property: string
				): string | number => {
					const propPath: string[] = property.split('.');
					const value: string = propPath.reduce(
						(curObj: ILicense, prop: string) => {
							return curObj[prop];
						},
						sortLicense
					);

					return value;
				};
				this.licenseDataSource.sort = this.sort;
				this.licenseDataSource.paginator = this.paginator;
			}
		);
	}
}
