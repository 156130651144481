import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { IApiRouteRequest } from 'src/app/_models/apiroute';
import { ApiRouteService } from 'src/app/_services/license/apiroute.service';

/**
 * Modal for creating a new ApiRoute
 */
@Component({
	selector: 'app-create-api-route',
	templateUrl: './create-api-route.component.html',
	styleUrls: ['./create-api-route.component.scss']
})
export class CreateApiRouteComponent {
	public routeCtrl: FormControl = new FormControl();
	public methodCtrl: FormControl = new FormControl();
	public descriptionCtrl: FormControl = new FormControl();
	public createApiRouteForm: FormGroup = new FormGroup({
		routeCtrl: this.routeCtrl,
		methodCtrl: this.methodCtrl,
		descriptionCtrl: this.descriptionCtrl
	});

	constructor(
		public apiRouteService: ApiRouteService,
		public dialogRef: MatDialogRef<CreateApiRouteComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IApiRouteRequest
	) {}

	public async onConfirm(): Promise<void> {
		if (this.createApiRouteForm.valid) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public async submit(): Promise<void> {
		const body: IApiRouteRequest = {
			route: this.routeCtrl.value,
			method: this.methodCtrl.value,
			description: this.descriptionCtrl.value
		};

		await this.apiRouteService.createApiRoute(body);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}
}
