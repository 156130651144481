<div class="deleteUserModal">
	<h1 mat-dialog-title>
		User löschen
	</h1>

	<form name="deleteForm">
		<div class="delete-form" mat-dialog-content>
			<p>Geben Sie zum Löschen die Id des Nutzers ein:</p>
			<mat-form-field appearance="outline">
				<input matInput placeholder="User Id" [formControl]="idCtrl" required />
			</mat-form-field>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				mat-raised-button
				color="warn"
				(click)="onConfirm()"
				[disabled]="deleteForm.invalid"
			>
				Löschen
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
