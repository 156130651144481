import { Injectable } from '@angular/core';
import { Router, NavigationStart, Event } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

interface IAlert {
	type: string;
	text: string;
}
/**
 * Zeigt Toasts an
 *
 * @export
 * @class AlertService
 */
@Injectable({ providedIn: 'root' })
export class AlertService {
	private readonly subject: Subject<IAlert> = new Subject<IAlert>();
	private keepAfterRouteChange: boolean = false;

	constructor(
		private readonly router: Router,
		private readonly _snackBar: MatSnackBar
	) {
		this.router.events.subscribe((event: Event): void => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterRouteChange) {
					this.keepAfterRouteChange = false;
				} else {
					this.clear();
				}
			}
		});
	}

	public getAlert(): Observable<IAlert> {
		return this.subject.asObservable();
	}

	public success(message: string, keepAfterRouteChange: boolean = false): void {
		this.keepAfterRouteChange = keepAfterRouteChange;
		const type: string = 'success';
		this.subject.next({ type: type, text: message });
		this._openSnackBar(message, type);
	}

	public error(message: string, keepAfterRouteChange: boolean = false): void {
		this.keepAfterRouteChange = keepAfterRouteChange;
		const type: string = 'error';
		this.subject.next({ type: type, text: message });
		this._openSnackBar(message, type);
	}

	public clear(): void {
		//clear by calling subject.next() without parameters
		this.subject.next();
	}

	private _openSnackBar(message: string, type: string): void {
		this._snackBar.open(message, 'OK', {
			duration: 6000,
			horizontalPosition: 'end',
			verticalPosition: 'top',
			panelClass: [type + '-snackbar']
		});
	}
}
