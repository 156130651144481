import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IGroupResponse, IGroupRequest } from 'src/app/_models/groups';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';

/**
 * Groups Migration Service for creating mappings for
 * Parse
 *
 * @export
 * @class GroupsMigrationService
 */
@Injectable({
	providedIn: 'root'
})
export class GroupsMigrationService {
	public readonly groups: Observable<IGroupResponse[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _groupsSubject: BehaviorSubject<
		IGroupResponse[]
	> = new BehaviorSubject<IGroupResponse[]>({} as IGroupResponse[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.groups = this._groupsSubject.asObservable();
	}

	public async getGroups(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/maintenance/groups';

		this.httpService
			.getData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(groups: IGroupResponse[]) => {
					this._groupsSubject.next(groups);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createMapping(id: string, body: IGroupRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/maintenance/groups/${id}`;
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Mapping wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen des Mappings fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}
}
