import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { IUserRequest } from 'src/app/_models/users';
import { UsersMigrationService } from 'src/app/_services/users/user-migration.service';
import { Subscription } from 'rxjs';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { IGroupResponse } from 'src/app/_models/groups';
import { ILicense } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

interface ICreateInformation {
	id: string;
	name: string;
}

/**
 * Create UserMapping Modal
 *
 * @export
 * @class CreateUserMappingModalComponent
 */
@Component({
	selector: 'app-create-user-mapping-modal',
	templateUrl: './create-user-mapping-modal.component.html',
	styleUrls: ['./create-user-mapping-modal.component.scss']
})
export class CreateUserMappingModalComponent implements OnInit {
	public groupCtrl: FormControl = new FormControl();
	public licenseCtrl: FormControl = new FormControl();
	public createMappingForm: FormGroup = new FormGroup({
		groupCtrl: this.groupCtrl,
		licenseCtrl: this.licenseCtrl
	});

	public licenses: ILicense[] = [];
	public groups: IGroupResponse[] = [];
	private groupSubscription: Subscription;
	private licenseSubscription: Subscription;

	constructor(
		public userMigrationService: UsersMigrationService,
		public groupService: GroupsService,
		public licenseService: LicenseService,
		public dialogRef: MatDialogRef<CreateUserMappingModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ICreateInformation
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.groupService.getGroups();
		await this.licenseService.getLicense();
	}

	public ngOnDestroy(): void {
		this.groupSubscription.unsubscribe();
		this.licenseSubscription.unsubscribe();
	}

	public groupDisplayFn(group: IGroupResponse): string {
		return group ? group.graphGroup.displayName : '';
	}

	public licenseDisplayFn(license: ILicense): string {
		return license ? license.name + ' - ' + license.id : '';
	}

	public async onConfirm(): Promise<void> {
		if (this.createMappingForm.valid) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(): Promise<void> {
		const licenseVal: string | undefined =
			this.licenseCtrl.value !== null ? this.licenseCtrl.value.id : undefined;

		const body: IUserRequest = {
			graphUser: undefined,
			groupId: this.groupCtrl.value.graphGroup.id,
			license: licenseVal
		};

		await this.userMigrationService.createMapping(this.data.id, body);
	}

	private async initSubscriptions(): Promise<void> {
		this.groupSubscription = this.groupService.groups.subscribe(
			(groups: IGroupResponse[]) => {
				this.groups = groups;
			}
		);

		this.licenseSubscription = this.licenseService.license.subscribe(
			(licenses: ILicense[]) => {
				this.licenses = licenses;
			}
		);
	}
}
