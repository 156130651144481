import { Component, OnInit, Inject } from '@angular/core';
import {
	FormControl,
	Form,
	FormGroup,
	ValidatorFn,
	ValidationErrors
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/_services/users/users.service';

/**
 * Delete User Modal
 *
 * @export
 * @class DeleteUserModalComponent
 */
@Component({
	selector: 'app-send-invitation-modal',
	templateUrl: './send-invitation-modal.component.html',
	styleUrls: ['./send-invitation-modal.component.scss']
})
export class SendInvitationModalComponent {
	constructor(
		public dialogRef: MatDialogRef<SendInvitationModalComponent>,
		private readonly usersService: UsersService,
		@Inject(MAT_DIALOG_DATA) public data: string
	) {}

	public async onConfirm(): Promise<void> {
		await this.submit();
		this.dialogRef.close(true);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(): Promise<void> {
		await this.usersService.sendInvitationEmail(this.data);
	}
}
