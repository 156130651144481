import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ILicense, ILicenseRequest } from 'src/app/_models/license';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';

/**
 * License Service for communication with the License-API
 *
 * @export
 * @class LicenseService
 */
@Injectable({
	providedIn: 'root'
})
export class LicenseService {
	public readonly license: Observable<ILicense[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _licenseSubject: BehaviorSubject<
		ILicense[]
	> = new BehaviorSubject<ILicense[]>({} as ILicense[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.license = this._licenseSubject.asObservable();
	}

	public async getLicense(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/license';
		this.httpService
			.getData<ILicense[]>(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(license: ILicense[]) => {
					this._licenseSubject.next(license);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createLicense(body: ILicenseRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/license';
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Lizenz wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen der Lizenz fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async patchLicense(body: ILicenseRequest, id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/license/${id}`;
		this.httpService
			.patchData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Lizenz wurde erfolgreich geupdated');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Updaten der Lizenz fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async deleteLicense(id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/license/${id}`;
		this.httpService
			.deleteData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Lizenz wurde erfolgreich gelöscht');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Löschen der Lizenz fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}
}
