import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IApiRoute } from '../_models/apiroute';
import { ApiRouteService } from '../_services/license/apiroute.service';
import { CreateApiRouteComponent } from '../_components/apiroute/create-api-route/create-api-route.component';
import { DeleteApiRouteComponent } from '../_components/apiroute/delete-api-route/delete-api-route.component';
import { EditApiRouteComponent } from '../_components/apiroute/edit-api-route/edit-api-route.component';

/**
 * FrontendRoutesComponent is responsible for managing all RouterConfigs
 * for the Portal
 */
@Component({
	selector: 'app-apiroutes',
	templateUrl: './apiroutes.component.html',
	styleUrls: ['./apiroutes.component.scss']
})
export class ApiRoutesComponent implements OnInit {
	public initalData: IApiRoute[] = [];
	public apiRouteDataSource: MatTableDataSource<
		IApiRoute
	> = new MatTableDataSource(this.initalData);
	public displayedColumns: string[] = [
		'id',
		'description',
		'route',
		'method',
		'edit'
	];
	public isLoading: boolean = true;

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly apiRouteService: ApiRouteService,
		public readonly dialog: MatDialog
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.apiRouteService.getApiRoutes();
	}

	public async openCreateModal(): Promise<void> {
		const dialogRef: MatDialogRef<CreateApiRouteComponent> = this.dialog.open(
			CreateApiRouteComponent,
			{
				panelClass: 'custom-modalbox'
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.apiRouteService.getApiRoutes();
			}, 1000);
		}
	}

	public async openEditModal(group: IApiRoute): Promise<void> {
		const dialogRef: MatDialogRef<EditApiRouteComponent> = this.dialog.open(
			EditApiRouteComponent,
			{
				panelClass: 'custom-modalbox',
				data: group
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.apiRouteService.getApiRoutes();
		}
	}

	public async openDeleteModal(id: string): Promise<void> {
		const dialogRef: MatDialogRef<DeleteApiRouteComponent> = this.dialog.open(
			DeleteApiRouteComponent,
			{
				panelClass: 'custom-modalbox',
				data: id
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.apiRouteService.getApiRoutes();
		}
	}

	private initSubscriptions(): void {
		this.apiRouteService.apiRoutes.subscribe((apiRoutes: IApiRoute[]) => {
			if (apiRoutes.length > 0) {
				this.isLoading = false;
			}
			this.apiRouteDataSource = new MatTableDataSource<IApiRoute>(apiRoutes);
			this.apiRouteDataSource.sort = this.sort;
			this.apiRouteDataSource.paginator = this.paginator;
		});
	}
}
