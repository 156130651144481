import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { IGroupRequest } from 'src/app/_models/groups';
import { CompanyService } from 'src/app/_services/company.service';
import { ICompany } from 'src/app/_models/company';
import { Subscription } from 'rxjs';
import { ILicense } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

/**
 * Create Group Modal
 *
 * @export
 * @class CreateGroupModalComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'app-create-group-modal',
	templateUrl: './create-group-modal.component.html',
	styleUrls: ['./create-group-modal.component.scss']
})
export class CreateGroupModalComponent implements OnInit {
	public descriptionCtrl: FormControl = new FormControl();
	public nameCtrl: FormControl = new FormControl();
	public mailCtrl: FormControl = new FormControl();
	public companyCtrl: FormControl = new FormControl();
	public mailCheckCtrl: FormControl = new FormControl();
	public securityCheckCtrl: FormControl = new FormControl();
	public licenseCtrl: FormControl = new FormControl();

	public createForm: FormGroup = new FormGroup({
		descriptionCtrl: this.descriptionCtrl,
		nameCtrl: this.nameCtrl,
		mailCtrl: this.mailCtrl,
		companyCtrl: this.companyCtrl,
		mailCheckCtrl: this.mailCheckCtrl,
		securityCheckCtrl: this.securityCheckCtrl,
		licenseCtrl: this.licenseCtrl
	});

	public companies: ICompany[] = [];
	public licenses: ILicense[] = [];
	private companySubscription: Subscription;
	private licenseSubscription: Subscription;

	constructor(
		public dialogRef: MatDialogRef<CreateGroupModalComponent>,
		private readonly groupsService: GroupsService,
		private readonly companyService: CompanyService,
		private readonly licenseService: LicenseService
	) {
		this.initSubscriptions();
		this.securityCheckCtrl.setValue(true);
		this.mailCheckCtrl.setValue(false);
	}

	public async ngOnInit(): Promise<void> {
		await this.companyService.getCompanies();
		await this.licenseService.getLicense();
	}

	public ngOnDestroy(): void {
		this.companySubscription.unsubscribe();
		this.licenseSubscription.unsubscribe();
	}

	public async onConfirm(): Promise<void> {
		if (this.createForm.valid) {
			await this.submitForm();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	public companyDisplayFn(company: ICompany): string {
		return company ? company.name + ' - ' + company.id : '';
	}

	public licenseDisplayFn(license: ILicense): string {
		return license ? license.name + ' - ' + license.id : '';
	}

	private async submitForm(): Promise<void> {
		const companyId: string = this.companyCtrl.value.id.toString();
		const newGroup: IGroupRequest = {
			graphGroup: {
				description: this.descriptionCtrl.value,
				displayName: this.nameCtrl.value,
				mailNickname: this.mailCtrl.value,
				mailEnabled: this.mailCheckCtrl.value,
				securityEnabled: this.securityCheckCtrl.value,
				groupTypes: ['Unified']
			},
			license: this.licenseCtrl.value.id,
			mandantenId: companyId
		};

		await this.groupsService.createGroup(newGroup);
	}

	private async initSubscriptions(): Promise<void> {
		this.companySubscription = this.companyService.companies.subscribe(
			(companies: ICompany[]) => {
				this.companies = companies;
			}
		);

		this.licenseSubscription = this.licenseService.license.subscribe(
			(licences: ILicense[]) => {
				this.licenses = licences;
			}
		);
	}
}
