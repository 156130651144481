<div>
	<h1 mat-dialog-title>
		Frontend-Routen für AddOn "{{ this.addOn.name }}" bearbeiten
	</h1>

	<mat-dialog-content>
		<mat-selection-list
			*ngIf="this.frontendRoutes?.length > 0"
			[(ngModel)]="selectedFrontendRoutes"
			[compareWith]="compareFunction"
		>
			<mat-list-option
				class="selection-option stock-hover-option"
				*ngFor="let route of frontendRoutes"
				[value]="route"
			>
				<h4>{{ route.key }} ({{ route.description }})</h4>
			</mat-list-option>
		</mat-selection-list>
	</mat-dialog-content>

	<div mat-dialog-actions>
		<button mat-raised-button color="primary" (click)="onConfirm()">
			Speichern
		</button>
		<button mat-raised-button color="secondary" (click)="onClose()">
			Schließen
		</button>
	</div>
</div>
