import { Component, OnInit, Inject } from '@angular/core';
import { IAddOn, IAddOnRequest } from 'src/app/_models/addon';
import { ApiRouteService } from 'src/app/_services/license/apiroute.service';
import { Subscription } from 'rxjs';
import { IApiRoute } from 'src/app/_models/apiroute';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddOnService } from 'src/app/_services/license/addon.service';

/**
 * ApiRouteSelectorComponent is responsible for selecting ApiRoutes for a AddOn
 */
@Component({
	selector: 'app-api-route-selector',
	templateUrl: './api-route-selector.component.html',
	styleUrls: ['./api-route-selector.component.scss']
})
export class ApiRouteSelectorComponent implements OnInit {
	public apiRoutes: IApiRoute[] = [];
	public selectedApiRoutes: IApiRoute[] = [];
	private apiRouteSubscription: Subscription;

	constructor(
		private readonly apiRouteService: ApiRouteService,
		private readonly addOnService: AddOnService,
		public dialogRef: MatDialogRef<ApiRouteSelectorComponent>,
		@Inject(MAT_DIALOG_DATA) public addOn: IAddOn
	) {
		this.initSubscriptions();
	}

	public compareFunction(o1: IApiRoute, o2: IApiRoute): boolean {
		return o1.id === o2.id;
	}

	public async ngOnInit(): Promise<void> {
		this.apiRouteService.getApiRoutes();
	}

	public ngOnDestroy(): void {
		this.apiRouteSubscription.unsubscribe();
	}

	public checkIfRouteIsSelected(id: string): boolean {
		return this.addOn.apiRoutes.some((apiRoute: IApiRoute) => {
			return apiRoute.id === id;
		});
	}

	public async onConfirm(): Promise<void> {
		const toAdd: string[] = [];
		const toRemove: string[] = [];

		this.addOn.apiRoutes.forEach((apiRoute: IApiRoute) => {
			const isSelected: boolean = this.selectedApiRoutes.some(
				(selectedRoute: IApiRoute) => {
					return apiRoute.id === selectedRoute.id;
				}
			);
			if (!isSelected) {
				toRemove.push(apiRoute.id);
			}
		});

		this.selectedApiRoutes.forEach((apiRoute: IApiRoute) => {
			const isAlreadySelected: boolean = this.checkIfRouteIsSelected(
				apiRoute.id
			);
			if (!isAlreadySelected) {
				toAdd.push(apiRoute.id);
			}
		});

		await this.submit(toRemove, toAdd);
		this.dialogRef.close(true);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(toRemove: string[], toAdd: string[]): Promise<void> {
		if (toRemove.length > 0) {
			const deleteRequest: IAddOnRequest = {
				name: this.addOn.name,
				routes: undefined,
				apiRoutes: toRemove,
				remove: true
			};
			await this.addOnService.patchAddOn(deleteRequest, this.addOn.id);
		}

		if (toAdd.length > 0) {
			const addRequest: IAddOnRequest = {
				name: this.addOn.name,
				routes: undefined,
				apiRoutes: toAdd,
				remove: false
			};
			await this.addOnService.patchAddOn(addRequest, this.addOn.id);
		}
	}

	private async initSubscriptions(): Promise<void> {
		this.apiRouteSubscription = this.apiRouteService.apiRoutes.subscribe(
			(apiRoutes: IApiRoute[]) => {
				this.apiRoutes = apiRoutes;
				if (this.apiRoutes.length > 0) {
					this.apiRoutes.forEach((apiRoute: IApiRoute) => {
						if (this.checkIfRouteIsSelected(apiRoute.id)) {
							this.selectedApiRoutes.push(apiRoute);
						}
					});
				}
			}
		);
	}
}
