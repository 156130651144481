import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './_components/nav/nav.component';
import { NavigationBarComponent } from './_components/navigation-bar/navigation-bar.component';
import { AngularMaterialModule } from './shared/angular-material.module';
import { UsersComponent } from './users/users.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from './_services/alert.service';
import { AuthGuardService } from './_guards/auth-guard.service';
import { GroupsComponent } from './groups/groups.component';
import { CreateGroupModalComponent } from './_components/group/create-group-modal/create-group-modal.component';
import { EditGroupModalComponent } from './_components/group/edit-group-modal/edit-group-modal.component';
import { DeleteGroupModalComponent } from './_components/group/delete-group-modal/delete-group-modal.component';
import { UserMigrationComponent } from './user-migration/user-migration.component';
import { GroupMigrationComponent } from './group-migration/group-migration.component';
import { CreateUserMappingModalComponent } from './_components/user/create-user-mapping-modal/create-user-mapping-modal.component';
import { CreateGroupMappingModalComponent } from './_components/group/create-group-mapping-modal/create-group-mapping-modal.component';
import { CreateUserModalComponent } from './_components/user/create-user-modal/create-user-modal.component';
import { EditUserModalComponent } from './_components/user/edit-user-modal/edit-user-modal.component';
import { DeleteUserModalComponent } from './_components/user/delete-user-modal/delete-user-modal.component';
import { InviteUserModalComponent } from './_components/user/invite-user-modal/invite-user-modal.component';
import { LicenseComponent } from './license/license.component';
import { AddonComponent } from './addon/addon.component';
import { FrontendRoutesComponent } from './frontendroutes/frontendroutes.component';
import { ApiRoutesComponent } from './apiroutes/apiroutes.component';
import { CreateFrontendRouteComponent } from './_components/frontendroute/create-frontend-route/create-frontend-route.component';
import { DeleteFrontendRouteComponent } from './_components/frontendroute/delete-frontend-route/delete-frontend-route.component';
import { EditFrontendRouteComponent } from './_components/frontendroute/edit-frontend-route/edit-frontend-route.component';
import { CreateApiRouteComponent } from './_components/apiroute/create-api-route/create-api-route.component';
import { DeleteApiRouteComponent } from './_components/apiroute/delete-api-route/delete-api-route.component';
import { EditApiRouteComponent } from './_components/apiroute/edit-api-route/edit-api-route.component';
import { ApiRouteSelectorComponent } from './_components/addon/api-route-selector/api-route-selector.component';
import { FrontendRouteSelectorComponent } from './_components/addon/frontend-route-selector/frontend-route-selector.component';
import { DeleteAddonComponent } from './_components/addon/delete-addon/delete-addon.component';
import { EditAddonComponent } from './_components/addon/edit-addon/edit-addon.component';
import { CreateAddonComponent } from './_components/addon/create-addon/create-addon.component';
import { AddOnSelectorComponent } from './_components/license/add-on-selector/add-on-selector.component';
import { EditLicenseComponent } from './_components/license/edit-license/edit-license.component';
import { DeleteLicenseComponent } from './_components/license/delete-license/delete-license.component';
import { CreateLicenseComponent } from './_components/license/create-license/create-license.component';
import { SendInvitationModalComponent } from './_components/user/send-invitation-modal/send-invitation-modal.component';

/**
 * Grundmodule
 *
 * @export
 * @class AppModule
 */
@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		NavigationBarComponent,
		UsersComponent,
		LoginComponent,
		GroupsComponent,
		CreateGroupModalComponent,
		EditGroupModalComponent,
		DeleteGroupModalComponent,
		UserMigrationComponent,
		GroupMigrationComponent,
		CreateUserMappingModalComponent,
		CreateGroupMappingModalComponent,
		CreateUserModalComponent,
		EditUserModalComponent,
		DeleteUserModalComponent,
		InviteUserModalComponent,
		SendInvitationModalComponent,
		LicenseComponent,
		AddonComponent,
		FrontendRoutesComponent,
		ApiRoutesComponent,
		CreateFrontendRouteComponent,
		DeleteFrontendRouteComponent,
		EditFrontendRouteComponent,
		CreateApiRouteComponent,
		DeleteApiRouteComponent,
		EditApiRouteComponent,
		ApiRouteSelectorComponent,
		FrontendRouteSelectorComponent,
		DeleteAddonComponent,
		EditAddonComponent,
		CreateAddonComponent,
		AddOnSelectorComponent,
		EditLicenseComponent,
		DeleteLicenseComponent,
		CreateLicenseComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		AngularMaterialModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule
	],
	providers: [
		AuthenticationService,
		AuthGuardService,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		AlertService
	],
	bootstrap: [AppComponent],
	entryComponents: []
})
export class AppModule {}
