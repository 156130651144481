import { Component, ChangeDetectorRef } from '@angular/core';
import {
	BreakpointObserver,
	Breakpoints,
	BreakpointState
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/authentication.service';

/**
 * Side menu component
 *
 * @export
 * @class NavComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss']
})
export class NavComponent {
	public isHandset: boolean;
	public isHandset$: Observable<boolean> = this.breakpointObserver
		.observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait])
		.pipe(
			map((result: BreakpointState) => {
				this.isHandset = result.matches;

				return result.matches;
			}),
			tap(() => {
				return this.changeDetectorRef.detectChanges();
			})
		);

	constructor(
		public readonly authenticationService: AuthenticationService,
		private readonly breakpointObserver: BreakpointObserver,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {}
}
