import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { FrontendRouteService } from 'src/app/_services/license/frontendroutes.service';
import { AddOnService } from 'src/app/_services/license/addon.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAddOn, IAddOnRequest } from 'src/app/_models/addon';
import IFrontendRoute from 'src/app/_models/frontendroute';

/**
 * Component for managing frontendroutes for a addon
 */
@Component({
	selector: 'app-frontend-route-selector',
	templateUrl: './frontend-route-selector.component.html',
	styleUrls: ['./frontend-route-selector.component.scss']
})
export class FrontendRouteSelectorComponent implements OnInit {
	public frontendRoutes: IFrontendRoute[] = [];
	public selectedFrontendRoutes: IFrontendRoute[] = [];
	private frontendRouteSubscription: Subscription;

	constructor(
		private readonly frontendRouteService: FrontendRouteService,
		private readonly addOnService: AddOnService,
		public dialogRef: MatDialogRef<FrontendRouteSelectorComponent>,
		@Inject(MAT_DIALOG_DATA) public addOn: IAddOn
	) {
		this.initSubscriptions();
	}

	public compareFunction(o1: IFrontendRoute, o2: IFrontendRoute): boolean {
		return o1.id === o2.id;
	}

	public async ngOnInit(): Promise<void> {
		this.frontendRouteService.getFrontendRoutes();
	}

	public ngOnDestroy(): void {
		this.frontendRouteSubscription.unsubscribe();
	}

	public checkIfRouteIsSelected(id: string): boolean {
		return this.addOn.routes.some((route: IFrontendRoute) => {
			return route.id === id;
		});
	}

	public async onConfirm(): Promise<void> {
		const toAdd: string[] = [];
		const toRemove: string[] = [];

		this.addOn.routes.forEach((routes: IFrontendRoute) => {
			const isSelected: boolean = this.selectedFrontendRoutes.some(
				(selectedRoute: IFrontendRoute) => {
					return routes.id === selectedRoute.id;
				}
			);
			if (!isSelected) {
				toRemove.push(routes.id);
			}
		});

		this.selectedFrontendRoutes.forEach((apiRoute: IFrontendRoute) => {
			const isAlreadySelected: boolean = this.checkIfRouteIsSelected(
				apiRoute.id
			);
			if (!isAlreadySelected) {
				toAdd.push(apiRoute.id);
			}
		});

		await this.submit(toRemove, toAdd);
		this.dialogRef.close(true);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async submit(toRemove: string[], toAdd: string[]): Promise<void> {
		if (toRemove.length > 0) {
			const deleteRequest: IAddOnRequest = {
				name: this.addOn.name,
				routes: toRemove,
				apiRoutes: undefined,
				remove: true
			};
			await this.addOnService.patchAddOn(deleteRequest, this.addOn.id);
		}

		if (toAdd.length > 0) {
			const addRequest: IAddOnRequest = {
				name: this.addOn.name,
				routes: toAdd,
				apiRoutes: undefined,
				remove: false
			};
			await this.addOnService.patchAddOn(addRequest, this.addOn.id);
		}
	}

	private async initSubscriptions(): Promise<void> {
		this.frontendRouteSubscription = this.frontendRouteService.frontendRoutes.subscribe(
			(frontendRoutes: IFrontendRoute[]) => {
				this.frontendRoutes = frontendRoutes;
				if (this.frontendRoutes.length > 0) {
					this.frontendRoutes.forEach((frontendRoute: IFrontendRoute) => {
						if (this.checkIfRouteIsSelected(frontendRoute.id)) {
							this.selectedFrontendRoutes.push(frontendRoute);
						}
					});
				}
			}
		);
	}
}
