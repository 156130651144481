import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertService, AuthenticationService } from '../_services';
/**
 * Login Klasse
 *
 * @export
 * @class LoginComponent
 * @implements {OnInit}
 */
@Component({
	templateUrl: 'login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	private returnUrl: string;
	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly authenticationService: AuthenticationService,
		private readonly alertService: AlertService
	) {
		if (this.authenticationService.isAuthenticated()) {
			this.router.navigate(['/users']);
		}
	}

	public ngOnInit(): void {
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	}

	public async login(): Promise<void> {
		await this.authenticationService.login();
		this.router.navigate(['/users']);
		this.alertService.clear();
	}
}
