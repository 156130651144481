<div class="createRouteModal">
	<h1 mat-dialog-title>
		Neue API-Route erstellen
	</h1>

	<form name="createApiRouteForm">
		<div class="create-route-form" mat-dialog-content>
			<mat-form-field appearance="outline">
				<mat-label>API-Route</mat-label>
				<input
					matInput
					placeholder="Route"
					[formControl]="routeCtrl"
					required
				/>
				<mat-hint>Die gewünschte API-Route</mat-hint>
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-label>Beschreibung</mat-label>
				<input
					matInput
					placeholder="Beschreibung"
					[formControl]="descriptionCtrl"
					required
				/>
				<mat-hint>Beschreibung was die Route macht</mat-hint>
			</mat-form-field>

			<mat-form-field appearance="outline">
				<mat-label>HTTP-Methode</mat-label>
				<input
					matInput
					placeholder="HTTP-Methode"
					[formControl]="methodCtrl"
					required
				/>
				<mat-hint>Die gewünschte HTTP-Methode</mat-hint>
			</mat-form-field>
		</div>

		<div class="confirmationBtn" mat-dialog-actions>
			<button
				mat-raised-button
				[disabled]="this.createApiRouteForm.invalid"
				color="primary"
				(click)="onConfirm()"
			>
				Speichern
			</button>
			<button mat-raised-button color="secondary" (click)="onClose()">
				Schließen
			</button>
		</div>
	</form>
</div>
