import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

/**
 * Auth Guard
 *
 * @export
 * @class AuthGuardService
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuardService implements CanActivate {
	constructor(public auth: AuthenticationService, public router: Router) {}

	public canActivate(): boolean {
		if (!this.auth.isAuthenticated()) {
			this.router.navigate(['login']);

			return false;
		}

		return true;
	}
}
