<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="apiRouteDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Parse Id</th>
			<td mat-cell *matCellDef="let apiRoute">{{ apiRoute.id }}</td>
		</ng-container>

		<!-- Beschreibung Column -->
		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Beschreibung</th>
			<td mat-cell *matCellDef="let apiRoute">{{ apiRoute.description }}</td>
		</ng-container>

		<!-- Route Column -->
		<ng-container matColumnDef="route">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Route</th>
			<td mat-cell *matCellDef="let apiRoute">{{ apiRoute.route }}</td>
		</ng-container>

		<!-- Method Column -->
		<ng-container matColumnDef="method">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>HTTP-Methode</th>
			<td mat-cell *matCellDef="let apiRoute">{{ apiRoute.method }}</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit" class="edit-column">
			<th mat-header-cell class="column-right-align" *matHeaderCellDef>
				Optionen
			</th>
			<td mat-cell class="column-right-align" *matCellDef="let apiRoute">
				<div class="button-div">
					<button
						class="edit-btn"
						mat-raised-button
						color="primary"
						(click)="openEditModal(apiRoute)"
					>
						<mat-icon>edit</mat-icon>
					</button>
					<button
						class="delete-btn"
						mat-raised-button
						color="warn"
						(click)="openDeleteModal(apiRoute.id)"
					>
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let apiRoute; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

	<button class="add-btn" mat-fab color="primary" (click)="openCreateModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>
