import { Component } from '@angular/core';

/**
 * App Component
 *
 * @export
 * @class AppComponent
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	public title: string = 'BIAdminPortal';
}
