<h1 mat-dialog-title>AddOns für Lizenz "{{ this.license.name }}" bearbeiten</h1>

<mat-dialog-content>
	<mat-selection-list
		*ngIf="this.addOns?.length > 0"
		[(ngModel)]="selectedAddOns"
		[compareWith]="compareFunction"
	>
		<mat-list-option
			class="selection-option stock-hover-option"
			*ngFor="let addOn of addOns"
			[value]="addOn"
		>
			{{ addOn.name }}
		</mat-list-option>
	</mat-selection-list>
</mat-dialog-content>

<div mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onConfirm()">
		Speichern
	</button>
	<button mat-raised-button color="secondary" (click)="onClose()">
		Schließen
	</button>
</div>
