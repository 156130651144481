<div class="spinner-wrapper" *ngIf="this.isLoading">
	<mat-spinner color="accent" class="spinner"></mat-spinner>
</div>

<div class="table-wrapper mat-elevation-z8">
	<table
		mat-table
		class="full-width-table"
		matSort
		aria-label="Elements"
		[dataSource]="addOnDataSource"
	>
		<!-- Id Column -->
		<ng-container matColumnDef="addOn.id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Parse Id</th>
			<td mat-cell *matCellDef="let addOn">{{ addOn.id }}</td>
		</ng-container>

		<!-- Route Column -->
		<ng-container matColumnDef="addOn.name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name des AddOns</th>
			<td mat-cell *matCellDef="let addOn">{{ addOn.name }}</td>
		</ng-container>

		<!-- API-Routes Column -->
		<ng-container matColumnDef="addOn.apiRoutes">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>API-Routen</th>
			<td mat-cell *matCellDef="let addOn">
				<mat-chip-list>
					<mat-chip
						class="chip-style"
						color="primary"
						(click)="openApiSelection(addOn)"
						selected
					>
						{{ addOn.apiRoutes.length }}</mat-chip
					>
				</mat-chip-list>
			</td>
		</ng-container>

		<!-- Frontend-Routes Column -->
		<ng-container matColumnDef="addOn.frontendRoutes">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Frontend-Routen</th>
			<td mat-cell *matCellDef="let addOn">
				<mat-chip-list>
					<mat-chip
						class="chip-style"
						color="primary"
						(click)="openFrontendRouteSelection(addOn)"
						selected
					>
						{{ addOn.routes.length }}</mat-chip
					>
				</mat-chip-list>
			</td>
		</ng-container>

		<!-- Options -->
		<ng-container matColumnDef="edit" class="edit-column">
			<th mat-header-cell class="column-right-align" *matHeaderCellDef>
				Optionen
			</th>
			<td mat-cell class="column-right-align" *matCellDef="let apiRoute">
				<button
					mat-raised-button
					color="primary"
					(click)="openEditModal(apiRoute)"
				>
					<mat-icon>edit</mat-icon>
				</button>
				<button
					class="delete-btn"
					mat-raised-button
					color="warn"
					(click)="openDeleteModal(apiRoute.id)"
				>
					<mat-icon>delete</mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let apiRoute; columns: displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

	<button class="add-btn" mat-fab color="primary" (click)="openCreateModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>
