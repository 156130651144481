import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IAddOn, IAddOnRequest } from 'src/app/_models/addon';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';

/**
 * AddOn Service for communication with the addOn-API
 *
 * @export
 * @class AddOnService
 */
@Injectable({
	providedIn: 'root'
})
export class AddOnService {
	public readonly addOns: Observable<IAddOn[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _addOnSubject: BehaviorSubject<
		IAddOn[]
	> = new BehaviorSubject<IAddOn[]>({} as IAddOn[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.addOns = this._addOnSubject.asObservable();
	}

	public async getAddOns(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/addon';
		this.httpService
			.getData<IAddOn[]>(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(addOns: IAddOn[]) => {
					this._addOnSubject.next(addOns);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createAddOn(body: IAddOnRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/addon';
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('AddOn wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen des AddOns fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async patchAddOn(body: IAddOnRequest, id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/addon/${id}`;
		this.httpService
			.patchData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('AddOn wurde erfolgreich geupdated');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Updaten des AddOns fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async deleteAddOn(id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/addon/${id}`;
		this.httpService
			.deleteData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('AddOn wurde erfolgreich gelöscht');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Löschen des AddOns fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}
}
