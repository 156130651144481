import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/_services/users/users.service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { IUserRequest, IUserResponse } from 'src/app/_models/users';
import { IGroupResponse } from 'src/app/_models/groups';
import { Subscription } from 'rxjs';
import { GroupsService } from 'src/app/_services/groups/groups.service';
import { ILicense } from 'src/app/_models/license';
import { LicenseService } from 'src/app/_services/license/license.service';

/**
 * Edit User Modal
 *
 * @export
 * @class EditUserModalComponent
 */
@Component({
	selector: 'app-edit-user-modal',
	templateUrl: './edit-user-modal.component.html',
	styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit {
	public nameCtrl: FormControl = new FormControl();
	public groupCtrl: FormControl = new FormControl();
	public licenseCtrl: FormControl = new FormControl();
	public editForm: FormGroup = new FormGroup({
		nameCtrl: this.nameCtrl,
		groupCtrl: this.groupCtrl,
		licenseCtrl: this.licenseCtrl
	});

	public licenses: ILicense[] = [];
	public groups: IGroupResponse[] = [];
	private groupSubscription: Subscription;
	private licenseSubscription: Subscription;

	constructor(
		public dialogRef: MatDialogRef<EditUserModalComponent>,
		private readonly usersService: UsersService,
		private readonly groupService: GroupsService,
		private readonly licenseService: LicenseService,
		@Inject(MAT_DIALOG_DATA) public user: IUserResponse
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		this.nameCtrl.setValue(this.user.graphUser.displayName);
		this.licenseCtrl.setValue(this.user.userMapping.license);
		await this.groupService.getGroups();
		await this.licenseService.getLicense();
	}

	public ngOnDestroy(): void {
		this.groupSubscription.unsubscribe();
		this.licenseSubscription.unsubscribe();
	}

	public groupDisplayFn(group: IGroupResponse): string {
		return group ? group.graphGroup.displayName : '';
	}

	public licenseDisplayFn(license: ILicense): string {
		return license ? license.name + ' - ' + license.id : '';
	}

	public async onConfirm(): Promise<void> {
		if (this.editForm.valid && this.valuesChanged()) {
			await this.submit();
			this.dialogRef.close(true);
		} else {
			this.dialogRef.close(false);
		}
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private valuesChanged(): boolean {
		if (
			this.nameCtrl.value !== this.user.graphUser.displayName ||
			this.licenseCtrl.value !== this.user.userMapping.license ||
			this.groupCtrl.value !== this.user.userMapping.group.groupId
		) {
			return true;
		}

		return false;
	}

	private async submit(): Promise<void> {
		const displayName: string | undefined =
			this.nameCtrl.value !== this.user.graphUser.displayName
				? this.nameCtrl.value
				: undefined;
		const licenseVal: string =
			this.licenseCtrl.value.id === undefined ? '' : this.licenseCtrl.value.id;
		const graphUser: MicrosoftGraph.User | undefined =
			displayName === undefined
				? undefined
				: {
						displayName: displayName
				  };

		const patchBody: IUserRequest = {
			graphUser: graphUser,
			license:
				licenseVal !== this.user.userMapping.license ? licenseVal : undefined,
			groupId:
				this.groupCtrl.value.graphGroup.id !==
				this.user.userMapping.group.groupId
					? this.groupCtrl.value.graphGroup.id
					: undefined
		};

		await this.usersService.patchUser(patchBody, this.user.graphUser.id);
	}

	private async initSubscriptions(): Promise<void> {
		this.groupSubscription = this.groupService.groups.subscribe(
			(groups: IGroupResponse[]) => {
				this.groups = groups;

				if (this.groups?.length > 0) {
					const group: IGroupResponse = this.groups.find(
						(currentGroup: IGroupResponse) => {
							return (
								currentGroup.graphGroup.id ===
								this.user.userMapping.group.groupId
							);
						}
					);
					this.groupCtrl.setValue(group);
				}
			}
		);

		this.licenseSubscription = this.licenseService.license.subscribe(
			(licenses: ILicense[]) => {
				this.licenses = licenses;
			}
		);
	}
}
