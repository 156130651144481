import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CreateGroupModalComponent } from '../_components/group/create-group-modal/create-group-modal.component';
import { GroupsService } from '../_services/groups/groups.service';
import { IGroupResponse } from '../_models/groups';
import { EditGroupModalComponent } from '../_components/group/edit-group-modal/edit-group-modal.component';
import { DeleteGroupModalComponent } from '../_components/group/delete-group-modal/delete-group-modal.component';

/**
 * Groups component
 *
 * @export
 * @class GroupsComponent
 */
@Component({
	selector: 'app-groups',
	templateUrl: './groups.component.html',
	styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
	public isLoading: boolean = true;
	public initalData: IGroupResponse[] = [];
	public groupsDataSource: MatTableDataSource<
		IGroupResponse
	> = new MatTableDataSource(this.initalData);
	public displayedColumns: string[] = [
		'graphGroup.id',
		'graphGroup.displayName',
		'groupMapping.mandantenId',
		'groupMapping.license',
		'edit'
	];

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly groupsService: GroupsService,
		public readonly dialog: MatDialog
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.groupsService.getGroups();
	}

	public async openCreateModal(): Promise<void> {
		const dialogRef: MatDialogRef<CreateGroupModalComponent> = this.dialog.open(
			CreateGroupModalComponent,
			{
				panelClass: 'custom-modalbox'
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.groupsService.getGroups();
			}, 1000);
		}
	}

	public async openEditModal(group: IGroupResponse): Promise<void> {
		const dialogRef: MatDialogRef<EditGroupModalComponent> = this.dialog.open(
			EditGroupModalComponent,
			{
				panelClass: 'custom-modalbox',
				data: group
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.groupsService.getGroups();
		}
	}

	public async openDeleteModal(id: string): Promise<void> {
		const dialogRef: MatDialogRef<DeleteGroupModalComponent> = this.dialog.open(
			DeleteGroupModalComponent,
			{
				panelClass: 'custom-modalbox',
				data: id
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.groupsService.getGroups();
		}
	}

	private initSubscriptions(): void {
		this.groupsService.groups.subscribe((groups: IGroupResponse[]) => {
			if (groups.length > 0) {
				this.isLoading = false;
			}
			this.groupsDataSource = new MatTableDataSource<IGroupResponse>(groups);
			this.groupsDataSource.sortingDataAccessor = (
				sortGroups: IGroupResponse,
				property: string
			): string | number => {
				const propPath: string[] = property.split('.');
				const value: string = propPath.reduce(
					(curObj: IGroupResponse, prop: string) => {
						return curObj[prop];
					},
					sortGroups
				);

				return value;
			};
			this.groupsDataSource.sort = this.sort;
			this.groupsDataSource.paginator = this.paginator;
		});
	}
}
