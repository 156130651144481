import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GroupsMigrationService } from '../_services/groups/groups-migration.service';
import { IGroupResponse } from '../_models/groups';
import { CreateGroupMappingModalComponent } from '../_components/group/create-group-mapping-modal/create-group-mapping-modal.component';

/**
 * Group Migration component
 *
 * @export
 * @class GroupMigrationComponent
 */
@Component({
	selector: 'app-group-migration',
	templateUrl: './group-migration.component.html',
	styleUrls: ['./group-migration.component.scss']
})
export class GroupMigrationComponent implements OnInit {
	public initialData: IGroupResponse[] = [];
	public groupsDataSource: MatTableDataSource<
		IGroupResponse
	> = new MatTableDataSource(this.initialData);
	public displayedColumns: string[] = [
		'graphGroup.id',
		'graphGroup.displayName',
		'edit'
	];
	public isLoading: boolean = true;

	private groupSub: Subscription;

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly groupsMigrationService: GroupsMigrationService,
		public readonly dialog: MatDialog
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.groupsMigrationService.getGroups();
	}

	public ngOnDestory(): void {
		this.groupSub.unsubscribe();
	}

	public async openCreateMapping(id: string, name: string): Promise<void> {
		const dialogRef: MatDialogRef<CreateGroupMappingModalComponent> = this.dialog.open(
			CreateGroupMappingModalComponent,
			{
				panelClass: 'custom-modalbox',
				data: { id: id, name: name }
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.groupsMigrationService.getGroups();
			}, 1000);
		}
	}

	private initSubscriptions(): void {
		this.groupSub = this.groupsMigrationService.groups.subscribe(
			(users: IGroupResponse[]) => {
				if (users.length > 0) {
					this.isLoading = false;
				}
				this.groupsDataSource = new MatTableDataSource<IGroupResponse>(users);
				this.groupsDataSource.sortingDataAccessor = (
					sortGroups: IGroupResponse,
					property: string
				): string | number => {
					const propPath: string[] = property.split('.');
					const value: string = propPath.reduce(
						(curObj: IGroupResponse, prop: string) => {
							return curObj[prop];
						},
						sortGroups
					);

					return value;
				};
				this.groupsDataSource.sort = this.sort;
				this.groupsDataSource.paginator = this.paginator;
			}
		);
	}
}
