import { Component, OnInit } from '@angular/core';
import { IAddOn } from 'src/app/_models/addon';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AddOnService } from 'src/app/_services/license/addon.service';
import { LicenseService } from 'src/app/_services/license/license.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ILicenseRequest } from 'src/app/_models/license';

/**
 * Creates a new License
 */
@Component({
	selector: 'app-create-license',
	templateUrl: './create-license.component.html',
	styleUrls: ['./create-license.component.scss']
})
export class CreateLicenseComponent implements OnInit {
	public addOns: IAddOn[] = [];
	public selectedAddOns: IAddOn[] = [];

	public nameCtrl: FormControl = new FormControl();
	public createAddOnForm: FormGroup = new FormGroup({
		nameCtrl: this.nameCtrl
	});

	private addOnSubscription: Subscription;

	constructor(
		private readonly addOnService: AddOnService,
		private readonly licenseService: LicenseService,
		public dialogRef: MatDialogRef<CreateLicenseComponent>
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.addOnService.getAddOns();
	}

	public ngOnDestroy(): void {
		this.addOnSubscription.unsubscribe();
	}

	public compareFunction(o1: IAddOn, o2: IAddOn): boolean {
		return o1.id === o2.id;
	}

	public async submit(): Promise<void> {
		const addOns: string[] = [];

		this.selectedAddOns.forEach((addOn: IAddOn) => {
			addOns.push(addOn.id);
		});

		const requestBody: ILicenseRequest = {
			name: this.nameCtrl.value,
			addOns: addOns,
			active: true,
			removeAddOn: undefined
		};

		await this.licenseService.createLicense(requestBody);
	}

	public async onConfirm(): Promise<void> {
		await this.submit();

		this.dialogRef.close(true);
	}

	public onClose(): void {
		this.dialogRef.close(false);
	}

	private async initSubscriptions(): Promise<void> {
		this.addOnSubscription = this.addOnService.addOns.subscribe(
			(addOns: IAddOn[]) => {
				this.addOns = addOns;
			}
		);
	}
}
