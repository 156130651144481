import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IGroupResponse, IGroupRequest } from '../../_models/groups';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { AlertService } from '../alert.service';
import { AuthenticationService } from '../authentication.service';

/**
 * Group Service for fetching and updating data
 *
 * @export
 * @class GroupsService
 */
@Injectable({
	providedIn: 'root'
})
export class GroupsService {
	public readonly groups: Observable<IGroupResponse[]>;

	private readonly proxyUrl: string = environment.ProxyApi;
	private readonly _groupsSubject: BehaviorSubject<
		IGroupResponse[]
	> = new BehaviorSubject<IGroupResponse[]>({} as IGroupResponse[]);

	constructor(
		private readonly httpService: HttpService,
		private readonly alertService: AlertService,
		private readonly authService: AuthenticationService
	) {
		this.groups = this._groupsSubject.asObservable();
	}

	public async getGroups(): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/groups';
		this.httpService
			.getData<IGroupResponse[]>(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				(groups: IGroupResponse[]) => {
					this._groupsSubject.next(groups);
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(error.error.error);
				}
			);
	}

	public async createGroup(body: IGroupRequest): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + 'api/groups';
		this.httpService
			.postData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Gruppe wurde erfolgreich angelegt');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Erstellen der Gruppe fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async patchGroup(body: IGroupRequest, id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/groups/${id}`;
		this.httpService
			.patchData(
				requestURL,
				body,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Gruppe wurde erfolgreich geupdated');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Updaten der Gruppe fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}

	public async deleteGroup(id: string): Promise<void> {
		await this.authService.checkAndRefreshToken();
		const requestURL: string = this.proxyUrl + `api/groups/${id}`;
		this.httpService
			.deleteData(
				requestURL,
				this.authService.idTokenValue,
				this.authService.accessTokenValue
			)
			.subscribe(
				() => {
					this.alertService.success('Gruppe wurde erfolgreich gelöscht');
				},
				(error: HttpErrorResponse) => {
					this.alertService.error(
						'Löschen der Gruppe fehlgeschlagen: ' + error.error.error
					);
				}
			);
	}
}
