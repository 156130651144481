<mat-toolbar color="primary" class="app-navigation-bar">
	<mat-toolbar-row>
		<button
			mat-icon-button
			(click)="toggleSidenav.emit()"
			*ngIf="
				this.authenticationService.isAuthenticated() && (isHandset$ | async)
			"
		>
			<mat-icon>menu</mat-icon>
		</button>

		<span *ngIf="!(isHandset$ | async)" class="toolbar-title">
			Admin-Backend
		</span>

		<div *ngIf="this.authenticationService.isAuthenticated()">
			<ul class="navigation-items">
				<li>
					<button mat-icon-button [matMenuTriggerFor]="auth">
						<mat-icon>person_outline</mat-icon>
					</button>
					<mat-menu #auth="matMenu">
						<button mat-menu-item disabled>
							<mat-icon>person_outline</mat-icon>
							<span>{{
								this.authenticationService.currentUserValue.username
							}}</span>
						</button>

						<mat-divider></mat-divider>

						<button mat-menu-item (click)="this.authenticationService.logout()">
							<mat-icon>exit_to_app</mat-icon>
							<span>Sign out</span>
						</button>
					</mat-menu>
				</li>
			</ul>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
