import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FrontendRouteService } from '../_services/license/frontendroutes.service';
import { CreateFrontendRouteComponent } from '../_components/frontendroute/create-frontend-route/create-frontend-route.component';
import { DeleteFrontendRouteComponent } from '../_components/frontendroute/delete-frontend-route/delete-frontend-route.component';
import { EditFrontendRouteComponent } from '../_components/frontendroute/edit-frontend-route/edit-frontend-route.component';
import IFrontendRoute from '../_models/frontendroute';

/**
 * FrontendRoutesComponent is responsible for managing all RouterConfigs
 * for the Portal
 */
@Component({
	selector: 'app-frontendroutes',
	templateUrl: './frontendroutes.component.html',
	styleUrls: ['./frontendroutes.component.scss']
})
export class FrontendRoutesComponent implements OnInit {
	public initalData: IFrontendRoute[] = [];
	public frontendRouteDataSource: MatTableDataSource<
		IFrontendRoute
	> = new MatTableDataSource(this.initalData);
	public displayedColumns: string[] = [
		'id',
		'key',
		'description',
		'config',
		'edit'
	];
	public isLoading: boolean = true;

	@ViewChild(MatPaginator) private readonly paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

	constructor(
		private readonly frontendRouteService: FrontendRouteService,
		public readonly dialog: MatDialog
	) {
		this.initSubscriptions();
	}

	public async ngOnInit(): Promise<void> {
		await this.frontendRouteService.getFrontendRoutes();
	}

	public async openCreateModal(): Promise<void> {
		const dialogRef: MatDialogRef<CreateFrontendRouteComponent> = this.dialog.open(
			CreateFrontendRouteComponent,
			{
				panelClass: 'custom-modalbox'
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.frontendRouteService.getFrontendRoutes();
			}, 1000);
		}
	}

	public async openEditModal(group: IFrontendRoute): Promise<void> {
		const dialogRef: MatDialogRef<EditFrontendRouteComponent> = this.dialog.open(
			EditFrontendRouteComponent,
			{
				panelClass: 'custom-modalbox',
				data: group
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			setTimeout(async () => {
				await this.frontendRouteService.getFrontendRoutes();
			}, 1000);
		}
	}

	public async openDeleteModal(id: string): Promise<void> {
		const dialogRef: MatDialogRef<DeleteFrontendRouteComponent> = this.dialog.open(
			DeleteFrontendRouteComponent,
			{
				panelClass: 'custom-modalbox',
				data: id
			}
		);
		const result: Promise<boolean> = await dialogRef.afterClosed().toPromise();
		if (result) {
			await this.frontendRouteService.getFrontendRoutes();
		}
	}

	private initSubscriptions(): void {
		this.frontendRouteService.frontendRoutes.subscribe(
			(frontendRoutes: IFrontendRoute[]) => {
				if (frontendRoutes.length > 0) {
					this.isLoading = false;
				}
				this.frontendRouteDataSource = new MatTableDataSource<IFrontendRoute>(
					frontendRoutes
				);
				this.frontendRouteDataSource.sort = this.sort;
				this.frontendRouteDataSource.paginator = this.paginator;
			}
		);
	}
}
